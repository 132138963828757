import React, { useEffect, useState } from 'react'

import {
  CountryCode,
  GoalsResponse,
  HFBShare,
  Insight,
  PppSalesBenchmark,
  SalesYtd,
  WeeklyHfbArticleSalesData,
  WeeklySalesAndQty,
  WeeklySalesData
} from '../../../../api/src/common-types'
import { ChartContainer } from '../../../components/BaseGraphs/ChartContainer'
import { stackedBarChart } from '../../../components/BaseGraphs/GraphUtil'
import { HFBSharesGraphNewKpi } from '../../../components/HFBSharesGraph'
import { Link } from '../../../components/Link'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import { AsIsSalesChart, PppSalesChart, TopArticlesList } from '../../../components/PPPSidebarGraphs'
import { TabBar } from '../../../components/TabBar'
import { TopBar } from '../../../components/TopBar'
import { formatNumberToCompact, formatAbsoluteNumber, formatRelativeNumber } from '../../../components/Utils/format'
import {
  getCountry,
  getCurrencyCode,
  getCurrencySymbol,
  getGoalsForLocation,
  getLocationOrDefault,
  isCluster,
  isSiteId
} from '../../../components/Utils/utils'
import {
  getAllHfbTopArticleSales,
  getGoals,
  getHFBPPPShares,
  getInsights,
  getPppSalesBenchmarking,
  getRolling12MonthsWeeklySales,
  getWeeklySales,
  getYtdSales,
  useEffectWithAbort
} from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'

import '../KPIPage.scss'
import colours from '../../../Colours.module.scss'
import { InPageInsights } from '../../../components/InPageInsights'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  KPI,
  KPIPerformance,
  HeadingItem,
  MainCard,
  RenderBenchmarkType,
  Stripe
} from '../../../components/KPIPage'
import classNames from 'classnames'
import { flatMap, sumBy } from 'lodash'
import Button from '@ingka/button'
import { isSameDay } from 'date-fns'
import { SumIndicator } from '../../../components/BaseGraphs/Indicators'
import { useLocations, useDataAvailabilityContext } from '../../../context'
import { Route } from '../../../routes'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { SwitchScopeBtn } from '../../../components/SwitchScopeBtn'
import InlineMessage from '@ingka/inline-message'
import FilterSelector from '../../../components/FilterSelector'

interface PppSalesBenchmarkWithGoal extends PppSalesBenchmark {
  goalPrevFY?: number
  goal?: number
  goalNextFY?: number
}

export const PppSalesKPIPage = () => {
  useDocumentTitle('People + Planet Positive Sales')

  const page = Route.PppSalesKPIPage
  const { currentLocation, locations } = useLocations()
  const [{ func, scope }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const [benchmarks, setBenchmarks] = React.useState<PppSalesBenchmarkWithGoal[]>()
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const [hfbSales, setHfbSales] = React.useState<HFBShare[]>([])
  const [insights, setInsights] = React.useState<Insight[]>([])
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [salesYtd, setSalesYtd] = React.useState<SalesYtd>()
  const [topArticles, setTopArticles] = React.useState<WeeklyHfbArticleSalesData[]>([])
  const [weeklySales, setWeeklySales] = React.useState<WeeklySalesAndQty[]>()
  const [goals, setGoals] = React.useState<GoalsResponse>()

  const locationId = getLocationOrDefault()
  const currencyCode = getCurrencyCode(currentLocation)
  const country = !isCluster(locationId) && locations.length > 0 ? getCountry(locationId, locations) : undefined
  const pppShareGoal = getGoalsForLocation(country?.countryCode ?? locationId, goals)?.pppShareGoal

  const filters: { label: string; disabled?: boolean }[] = [
    { label: 'all' },
    { label: 'Store' },
    { label: 'Online' },
    { label: 'Remote' }
  ]
  const isSales = !scope.includes('quantity')
  const [curScope, setcurScope] = React.useState<boolean>(isSales)

  const [selectedFilter, setSelectedFilter] = React.useState<string>(filters[0].label)
  const filterLabels: { [key: string]: string } = {
    all: 'All sales',
    Store: 'In-store',
    Online: 'Online',
    Remote: 'Remote'
  }

  const selector = {
    locationId,
    site: locationId.length > 3 ? locationId : 'ALL',
    countryCodes: locationId.length === 2 ? [locationId as CountryCode] : [],
    isOld: true
  }

  const rollingSelector = {
    ...selector,
    channel: selectedFilter
  }

  useEffectWithAbort(
    signal => {
      setLastUpdated('')
      setSalesYtd(undefined)
      getYtdSales(selector, signal).then(({ data, lastUpdated }) => {
        setSalesYtd(data)
        setLastUpdated(lastUpdated)
      })
    },
    [JSON.stringify(selector)]
  )

  useEffectWithAbort(
    signal => {
      setWeeklySales(undefined)
      getRolling12MonthsWeeklySales(rollingSelector, signal).then(setWeeklySales)
    },
    [JSON.stringify(rollingSelector)]
  )

  useEffectWithAbort(
    signal => {
      if (country) {
        const getGoalsAndBenchmarks = async () => {
          const [goals, apiBenchmarks] = await Promise.all([
            getGoals(country.countryCode, func, dataAvailability?.profitCurrentFY ?? 0),
            getPppSalesBenchmarking(country.countryCode, signal)
          ])
          setBenchmarks(
            apiBenchmarks.map(benchmark => ({
              ...benchmark,
              goalPrevFY: getGoalsForLocation(benchmark.id, goals)?.pppShareGoalPrevFY,
              goal: getGoalsForLocation(benchmark.id, goals)?.pppShareGoal,
              goalNextFY: getGoalsForLocation(benchmark.id, goals)?.pppShareGoalNextFY
            }))
          )
          setGoals(goals)
        }

        setBenchmarks(undefined)
        setGoals(undefined)
        getGoalsAndBenchmarks()
      }
    },
    [JSON.stringify(country), JSON.stringify(func)]
  )

  useEffectWithAbort(
    signal => {
      setHfbSales([])
      setTopArticles([])
      getAllHfbTopArticleSales(
        locationId,
        selectedFilter === 'all'
          ? 'total'
          : selectedFilter === 'Store'
          ? 'store'
          : selectedFilter === 'Online'
          ? 'online'
          : 'remote',
        isSales,
        signal
      ).then(setTopArticles)
      getHFBPPPShares(locationId, signal).then(setHfbSales)
    },
    [locationId, selectedFilter]
  )

  React.useEffect(() => {
    setInsights([])
    getInsights({
      locationId,
      func
    }).then(result => setInsights(result.filter(({ type }) => type === 'sales')))
  }, [locationId, JSON.stringify(func)])

  const [pppQtySharePrev, setPppQtySharePrev] = useState<number>()

  const countryBenchmark: PppSalesBenchmarkWithGoal = React.useMemo(() => {
    const currFyPppSales = salesYtd?.pppSales ?? 0
    const prevFyPppSales = sumBy(benchmarks, 'prevFyPppSales')
    const currFyTotalSales = sumBy(benchmarks, 'currFyTotalSales')
    const prevFyTotalSales = sumBy(benchmarks, 'prevFyTotalSales')
    const currFyTotalAsisQty = sumBy(benchmarks, 'currFyTotalAsisQty')
    const currFyTotalQty = sumBy(benchmarks, 'currFyTotalQty')
    const prevFyTotalAsisQty = sumBy(benchmarks, 'prevFyTotalAsisQty')
    const prevFyTotalQty = sumBy(benchmarks, 'prevFyTotalQty')

    setPppQtySharePrev(
      isSiteId(locationId)
        ? benchmarks?.find(b => b.id === locationId)?.prevFyPppQtyShare
        : prevFyTotalAsisQty / prevFyTotalQty
    )

    return {
      id: locationId,
      label: isSiteId(locationId)
        ? (benchmarks && benchmarks.find(b => b.id === locationId)?.label) ?? ''
        : country?.label ?? '',
      currFyPppSales,
      currFyTotalSales,
      currFyPppSalesShare: currFyPppSales / currFyTotalSales,
      prevFyPppSales,
      prevFyTotalSales,
      prevFyPppSalesShare: prevFyPppSales / prevFyTotalSales,
      currFyTotalAsisQty,
      currFyTotalQty,
      prevFyTotalAsisQty,
      prevFyTotalQty,
      currFyPppQtyShare: currFyTotalAsisQty / currFyTotalQty,
      prevFyPppQtyShare: prevFyTotalAsisQty / prevFyTotalQty,
      goalPrevFY: country?.countryCode
        ? getGoalsForLocation(country?.countryCode, goals)?.pppShareGoalPrevFY
        : undefined,
      goal: country?.countryCode ? getGoalsForLocation(country?.countryCode, goals)?.pppShareGoal : undefined,
      goalNextFY: country?.countryCode
        ? getGoalsForLocation(country?.countryCode, goals)?.pppShareGoalNextFY
        : undefined,
      selectable: true
    }
  }, [locationId, country?.countryCode, JSON.stringify(benchmarks), JSON.stringify(goals), salesYtd?.pppSales, isSales])

  const [selectedLocationBenchmark, comparisonBenchmarks] = React.useMemo(() => {
    if (benchmarks === undefined) {
      return [undefined, []]
    }
    const selectedLocationBenchmark = isSiteId(locationId)
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        benchmarks.find(b => b.id === locationId)!
      : countryBenchmark

    return [
      selectedLocationBenchmark,
      benchmarks
        .filter(b => b.id !== locationId)
        .sort((a, b) => b.currFyPppSalesShare - a.currFyPppSalesShare)
        .slice(0, selectedLocationBenchmark ? 3 : 4)
    ]
  }, [locationId, JSON.stringify(countryBenchmark), JSON.stringify(benchmarks)])

  const benchmarkValues = React.useMemo(() => {
    return comparisonBenchmarks
      ? [...(selectedLocationBenchmark ? [selectedLocationBenchmark] : []), ...comparisonBenchmarks]
      : []
  }, [comparisonBenchmarks, selectedLocationBenchmark])

  const renderBenchmark: RenderBenchmarkType<PppSalesBenchmarkWithGoal> = (benchmark, _keys, classes) => {
    const pppSalesShare =
      locationId === benchmark.id
        ? isSales
          ? salesYtd?.pppSalesShare
          : salesYtd?.pppQtyShare
        : isSales
        ? benchmark.currFyPppSalesShare
        : benchmark.currFyPppQtyShare

    const pppSalesSharePrev = isSales ? benchmark.prevFyPppSalesShare : benchmark.prevFyPppQtyShare

    return (
      <React.Fragment key={benchmark.id}>
        <div className={classNames('FirstItem', classes)}>{benchmark.label}</div>
        <div className={classNames('Right', classes, 'Black')}>
          {pppSalesSharePrev > 0 ? (pppSalesSharePrev * 100).toFixed(1) : 'N/A'}
        </div>
        <div
          className={classNames(
            'YTD',
            'Right',
            classes,
            pppSalesShare && benchmark.goal && isSales ? (pppSalesShare > benchmark.goal ? 'OnTrack' : 'YTD') : 'Black'
          )}
        >
          {pppSalesShare ? (pppSalesShare * 100).toFixed(1) : 'N/A'}
        </div>
        <div className={classNames('YTD', 'Right', 'Black', classes)}>
          {formatNumberToCompact(isSales ? benchmark.currFyPppSales : benchmark.currFyTotalAsisQty)}
        </div>
        {isSales && (
          <div className={classNames('YTD', 'Right', 'Black', classes)}>
            {benchmark.goalPrevFY ? formatRelativeNumber(benchmark.goalPrevFY * 100) : 'N/A'}
          </div>
        )}
        {isSales && (
          <div className={classNames('YTD', 'Right', 'Black', classes)}>
            {benchmark.goal ? formatRelativeNumber(benchmark.goal * 100) : 'N/A'}
          </div>
        )}
        {isSales && (
          <div className={classNames('YTD', 'Right', 'Black', classes)}>
            {benchmark.goalNextFY ? formatRelativeNumber(benchmark.goalNextFY * 100) : 'N/A'}
          </div>
        )}
        <div />
      </React.Fragment>
    )
  }

  const exploreButton = (
    <Link page={Route.PppSalesExplorePage}>
      <Button text="Open Explore" type="primary" small />
    </Link>
  )

  const benchmarkHeaders: HeadingItem<PppSalesBenchmarkWithGoal>[] = [
    [{ name: `FY${(dataAvailability?.profitPreviousFY ?? 2000) - 2000} YTD`, key: 'prevFyPppSalesShare' }],
    [{ name: `FY${(dataAvailability?.profitCurrentFY ?? 2000) - 2000} YTD`, key: 'currFyPppSalesShare' }],
    [
      {
        name: `FY${(dataAvailability?.profitCurrentFY ?? 2000) - 2000} YTD (${isSales ? currencyCode : 'QTY'})`,
        key: 'prevFyPppSalesShare'
      }
    ]
  ]

  const benchmarkHeadersWithGoals: HeadingItem<PppSalesBenchmarkWithGoal>[] = [
    ...benchmarkHeaders,
    [
      {
        name: `FY${((dataAvailability?.profitCurrentFY && dataAvailability?.profitCurrentFY - 1) ?? 2000) - 2000} Goal`,
        key: 'goalPrevFY'
      }
    ],
    [{ name: `FY${(dataAvailability?.profitCurrentFY ?? 2000) - 2000} Goal`, key: 'goal' }],
    [
      {
        name: `FY${((dataAvailability?.profitCurrentFY && dataAvailability?.profitCurrentFY + 1) ?? 2000) - 2000} Goal`,
        key: 'goalNextFY'
      }
    ]
  ]

  const filteredSales = weeklySales?.find(s => s.week === 36)
  const index = filteredSales && weeklySales?.indexOf(filteredSales)
  const filteredSalesSum = weeklySales?.slice(index).reduce((acc, currentValue) => {
    return acc + currentValue.pppSales
  }, 0)
  const filteredQtySum = weeklySales?.slice(index).reduce((acc, currentValue) => {
    return acc + (currentValue.pppQty ?? 0)
  }, 0)

  const salesKpi = salesYtd
    ? ([
        {
          key: 'P+PP Sales Share Index',
          value: salesYtd.pppSalesIndex ? formatRelativeNumber(salesYtd.pppSalesIndex * 100) : 'N/A'
        },
        {
          key: 'Total Sales',
          value: `${formatAbsoluteNumber(Math.round(salesYtd.totalSales))} ${currencyCode}`,
          tooltip: 'Total Sales in the calculation include all HFBs'
        },
        {
          key: 'P+PP Sales (incl. As-Is Sales)',
          value: `${formatAbsoluteNumber(Math.round(salesYtd.pppSales))} ${currencyCode}`
        },
        {
          key: 'P+PP Sales Share',
          value: `${salesYtd.pppSalesShare ? formatRelativeNumber(salesYtd.pppSalesShare * 100) : 'N/A'} %`,
          keyClassNames: ['Bold'],
          valueClassNames: [
            'Bold',
            pppShareGoal && salesYtd.pppSalesShare
              ? pppShareGoal < salesYtd.pppSalesShare
                ? 'OnTrack'
                : 'NotOnTrack'
              : ''
          ]
        },
        {
          key: isSiteId(locationId)
            ? `FY${(dataAvailability?.profitCurrentFY || 2025) - 2000} Goal (Country)`
            : `FY${(dataAvailability?.profitCurrentFY || 2025) - 2000} Goal`,
          value: `${pppShareGoal ? formatRelativeNumber(pppShareGoal * 100) : 'N/A'} %`,
          keyClassNames: ['Bold'],
          valueClassNames: ['Bold']
        }
      ].filter(Boolean) as KPI[])
    : []

  const qtyKpi = salesYtd
    ? ([
        {
          key: `P+PP Quantity Share FY${(dataAvailability?.profitPreviousFY ?? 2000) - 2000}`,
          value: pppQtySharePrev ? formatRelativeNumber(pppQtySharePrev * 100) : 'N/A',
          keyClassNames: ['Bold'],
          unit: '%',
          valueClassNames: ['Bold']
        },
        {
          key: 'P+PP Quantity Share',
          value: salesYtd.pppQtyShare ? formatRelativeNumber(salesYtd.pppQtyShare * 100) : 'N/A',
          keyClassNames: ['Bold'],
          unit: '%',
          valueClassNames: ['Bold']
        },
        {
          key: 'P+PP Quantity Share Index',
          value: salesYtd.pppQtyIndex ? formatRelativeNumber(salesYtd.pppQtyIndex * 100) : 'N/A'
        },
        {
          key: 'Total Quantity',
          value: `${formatAbsoluteNumber(Math.round(salesYtd.totalQtyWithoutHFB))} QTY`
        },
        {
          key: 'Total Quantity (Incl. Services)',
          value: `${formatAbsoluteNumber(Math.round(salesYtd.totalQty))} QTY`,
          tooltip: 'Total Quantity in the calculation include all HFBs'
        },
        {
          key: 'Total Home Furnishing Offer',
          value: `${formatAbsoluteNumber(Math.round(salesYtd.totalHFOQty))} QTY`
        },
        {
          key: 'Total Food',
          value: `${formatAbsoluteNumber(Math.round(salesYtd.totalFoodQty))} QTY`
        },
        {
          key: 'P+PP Quantity (Incl. As-Is Quantity)',
          value: `${formatAbsoluteNumber(Math.round(salesYtd.pppQty))} QTY`
        }
      ].filter(Boolean) as KPI[])
    : []

  return (
    <div className="KPIPage PppSales">
      <TopBar currentPage={page} useInFlexLayout exploreButton={exploreButton} />
      <PageHeader
        className="PppSalesHeader ClimateFootprintHeader  ClimateFootprintHeader-whithSBTi"
        route={Route.PppSalesKPIPage}
      >
        <SwitchScopeBtn
          setIsOld={setcurScope}
          isOld={curScope}
          textLeftBtn="Sales"
          textRightBtn="Quantity"
          queryParamFirst="sales"
          queryParamLast="quantity"
        />
      </PageHeader>
      <div className="PageContent">
        <Stripe title="People + Planet Positive Sales">
          <DataSourceAndModalButton
            dataSource="Delivered sales"
            lastUpdated={lastUpdated}
            updateFrequency="weekly"
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        <div className="InlineMessageWrapper">
          <InlineMessage
            body={
              'Please note that the FY25 range structure and the P+PP list (v18) are applied as a basis for the P+PP share in the 4P dashboard.'
            }
            variant="cautionary"
          />
        </div>
        <CardRow className="BenchmarkingAndGoals">
          <Benchmarking
            key="benchmarking"
            benchmarks={benchmarkValues}
            label="share %"
            headers={isSales ? benchmarkHeadersWithGoals : benchmarkHeaders}
            locationId={locationId}
            openModal={() => setBenchmarkModalOpen(true)}
            renderBenchmark={renderBenchmark}
            totalLocations={benchmarks?.length ?? 0}
            isArea
          />
          <KPIPerformance
            key="goals"
            units={['YTD']}
            heading="KPI Performance"
            kpis={isSales ? salesKpi : qtyKpi}
            classes={!isSales ? 'ppp-sales__performance' : undefined}
            noLastClass
          />
        </CardRow>
        <FilterSelector
          filters={filters}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterLabels={filterLabels}
          currTitle="sales channel filter"
        />
        <MainCard
          title={
            isSales
              ? 'Weekly People + Planet Positive sales vs. Other Sales'
              : 'Weekly People + Planet Positive Quantity vs. Other Quantity'
          }
          description={
            isSales
              ? 'Other Sales = Total Sales - People and Planet Positive Sales (Incl. As-Is Sales)'
              : 'Other Quantity = Total Quantity - People and Planet Positive Quantity (Incl. As-Is Quantity)'
          }
          subtitle={
            salesYtd && (
              <>
                {isSales
                  ? filteredSalesSum && formatAbsoluteNumber(Math.round(filteredSalesSum))
                  : filteredQtySum && formatAbsoluteNumber(Math.round(filteredQtySum))}{' '}
                <span className="Label">
                  {isSales && currencyCode}
                  {isSales ? ' P+PP Sales' : 'QTY P+PP Quantity'}
                </span>
              </>
            )
          }
        >
          <div className="GraphContainer">
            {weeklySales && weeklySales?.length > 0 ? (
              <PPPAndTotalSalesGraph weeklySales={weeklySales} currencyCode={currencyCode} isSales={isSales} />
            ) : (
              <LoadingSkeleton />
            )}
          </div>
        </MainCard>
        <CardRow>
          <PppAsisSalesGraphs
            key="ppp-asis"
            locationId={locationId}
            profitShareGoal={pppShareGoal}
            channel={selectedFilter}
            isSales={isSales}
          />
          {topArticles.length > 0 ? (
            <TopArticlesList
              key="top-articles"
              siteOrCountry={locationId}
              topArticles={topArticles}
              isSales={isSales}
            />
          ) : (
            <LoadingSkeleton />
          )}
        </CardRow>
        <MainCard>
          {hfbSales.length > 0 ? (
            <HFBSharesGraphNewKpi
              shares={hfbSales}
              siteOrCountry={locationId}
              channel={selectedFilter}
              isSales={isSales}
            />
          ) : (
            <LoadingSkeleton />
          )}
        </MainCard>
        {insights && insights.length > 0 && <InPageInsights insights={insights} />}
        <Stripe
          title="Want to learn more about P+PP Sales?"
          subtitle="Explore contains additional charts and time selections."
        >
          {exploreButton}
        </Stripe>
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks && (
        <BenchmarkingModal
          benchmarks={benchmarks}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={countryBenchmark}
          headers={isSales ? benchmarkHeadersWithGoals : benchmarkHeaders}
          isOpen={benchmarkModalOpen}
          locationId={locationId}
          renderBenchmark={renderBenchmark}
          sortBy="currFyPppSalesShare"
          sortDirection="desc"
          title="share %"
          isArea
        />
      )}
    </div>
  )
}

type Tab = 'P+PP Sales Share %' | 'As-Is Sales Share %' | 'P+PP Quantity Share in %' | 'As-Is Quantity Share in %'

interface PppAsisSalesGraphsProps {
  locationId: string
  profitShareGoal?: number
  channel: string
  isSales: boolean
}

const PppAsisSalesGraphs: React.FC<PppAsisSalesGraphsProps> = ({ locationId, profitShareGoal, channel, isSales }) => {
  const { dataAvailability } = useDataAvailabilityContext()
  const [tab, setTab] = React.useState<Tab>()
  const [sales, setSales] = React.useState<WeeklySalesData[]>([])

  useEffectWithAbort(
    signal => {
      setSales([])
      getWeeklySales(
        locationId,
        dataAvailability?.profitPreviousFY ?? 2000,
        dataAvailability?.profitCurrentFY ?? 2000,
        channel,
        signal
      ).then(sales => {
        if (sales.filter(d => d.lastUpdated != null).length > 0) {
          setSales(sales)
        }
      })
    },
    [locationId, dataAvailability?.profitPreviousFY, dataAvailability?.profitCurrentFY, channel]
  )

  useEffect(() => {
    setTab(isSales ? 'P+PP Sales Share %' : 'P+PP Quantity Share in %')
  }, [isSales])

  return (
    <div className="TabCard">
      <TabBar
        activeTab={tab}
        tabs={
          isSales
            ? ['P+PP Sales Share %', 'As-Is Sales Share %']
            : ['P+PP Quantity Share in %', 'As-Is Quantity Share in %']
        }
        onTabChanged={setTab}
      />
      {tab === (isSales ? 'P+PP Sales Share %' : 'P+PP Quantity Share in %') ? (
        sales.length > 0 ? (
          <PppSalesChart sales={sales} profitShareGoal={isSales ? profitShareGoal : undefined} isSales={isSales} />
        ) : (
          <LoadingSkeleton />
        )
      ) : sales.length > 0 ? (
        <AsIsSalesChart sales={sales} isSales={isSales} />
      ) : (
        <LoadingSkeleton />
      )}
    </div>
  )
}

interface PPPAndTotalSalesGraphProps {
  weeklySales: WeeklySalesAndQty[] | undefined
  currencyCode: string | undefined
  isSales: boolean
}

export const PPPAndTotalSalesGraph = ({ weeklySales, currencyCode, isSales }: PPPAndTotalSalesGraphProps) => {
  const pppSalesName = isSales ? 'P+PP (Incl. As-Is Sales)' : 'P+PP Quantity (Incl. As-Is Quantity)  '
  const otherSalesName = isSales ? 'Other Sales' : 'Other Quantity'

  const weeklySeries = React.useMemo(() => {
    return weeklySales
      ? [
          {
            name: pppSalesName,
            color: colours.salmon1,
            data: weeklySales.map(week => ({
              x: new Date(week.readableDate),
              y: isSales ? week.pppSales : week.pppQty ?? 0
            }))
          },
          {
            name: otherSalesName,
            color: colours.offWhite7,
            data: weeklySales.map(week => ({
              x: new Date(week.readableDate),
              y: (isSales ? week.totalSales : week.totalQty ?? 0) - (isSales ? week.pppSales : week.pppQty ?? 0)
            }))
          }
        ]
      : undefined
  }, [JSON.stringify(weeklySales), isSales])

  return (
    <ChartContainer
      domain={weeklySales?.map(({ readableDate }) => new Date(readableDate))}
      series={weeklySeries}
      generator={stackedBarChart('none')}
      dateFormat="week"
      yAxisTitle={isSales ? getCurrencySymbol(currencyCode) : 'Quantity'}
      tooltipSummary={date => {
        if (weeklySeries === undefined) {
          return []
        }
        const weeklyData = flatMap(weeklySeries, s => ({
          name: s.name,
          value: s.data.find(d => isSameDay(d.x, date))?.y
        }))
        const sum = weeklyData.reduce((acc, d) => acc + (d?.value ?? 0), 0)
        const share = weeklyData.find(d => d.name === pppSalesName)?.value ?? NaN / sum
        return [
          { title: 'Total', value: formatRelativeNumber(Math.round(sum)), icon: <SumIndicator /> },
          {
            title: isSales ? 'P+PP Sales share' : 'P+PP Quantity Share',
            value: Number.isNaN(share) ? 'N/A' : formatRelativeNumber((share / sum) * 100),
            icon: <SumIndicator />,
            unit: '%'
          }
        ]
      }}
    />
  )
}
