import React from 'react'
import { flatMap, isArray, isFunction } from 'lodash'
import classNames, { Argument } from 'classnames'
import SkapaModal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal'
import { KPIBenchmark } from '../../api/src/common-types'
import '../pages/KPIPages/KPIPage.scss'
import colours from '../Colours.module.scss'
import { LoadingSkeleton } from './LoadingSkeleton'
import { ArrowIcon } from './ArrowIcon'
import { TimeRangeSelector } from './Benchmark/TimeRangeSelector'
import Button from '@ingka/button'
import { Tooltip } from './Tooltip'
import { InformationIndicator } from './BaseGraphs/Indicators'
import { NoDataViewSmall } from './BaseGraphs/NoDataView'
import { formatRelativeNumber } from './Utils/format'
import { BetaLabel } from './Navigation'
import { Show } from './Conditions/Show'
import { Link } from '../components/Link'
import { ReactComponent as CoWorkerScreenIcon } from '../images/Icons/CoWorkerIcon.svg'
import { Route } from '../routes'
import { ReactComponent as ExportIcon } from '../images/Icons/Export.svg'
import { DateFormat } from '../components/BaseGraphs/GraphUtil'
import { Serie } from '../components/BaseGraphs/ChartContainer'
import { ChartDataContext } from '../pages/ExplorePages/ExploreGraphCard'
import { useLocations } from '../context'
import { exportGraphAsPng, exportGraphAsPptx } from '../lib/ExportGraph'
import { getLocationLabel } from '../components/Utils/utils'
import ArrowDownToBase from '../images/Icons/ArrowDownToBase'
import Resourse from '../images/Resourse'

function useBenchmarkingHeaders<T extends KPIBenchmark>(headers: HeadingItem<T>[]) {
  const [keys, setKeys] = React.useState<Array<keyof T>>([])

  React.useEffect(() => {
    if (flatMap(headers, h => h).length === 0) {
      setKeys([])
      return
    }
    setKeys(headers.filter(h => h.length > 0).map(h => h[0].key))
  }, [JSON.stringify(headers)])

  const updateHeaderKey = (key: keyof T, index: number) => {
    const state = [...keys]
    state[index] = key
    setKeys(state)
  }

  return [keys, updateHeaderKey] as const
}

export type RenderBenchmarkType<T> = (
  benchmark: T,
  keys: Array<keyof T>,
  classes: Argument,
  isModal?: boolean
) => JSX.Element

type HeadingMenuOption<T extends KPIBenchmark> = { name: string; description?: string; key: keyof T }
export type HeadingItem<T extends KPIBenchmark> = HeadingMenuOption<T>[]

interface BenchmarkingProps<T extends KPIBenchmark> {
  benchmarks: T[]
  label: string
  headers: HeadingItem<T>[]
  locationId: string
  openModal: () => void
  renderBenchmark: RenderBenchmarkType<T>
  totalLocations: number
  tooltip?: string
  isArea?: boolean
}

export function Benchmarking<T extends KPIBenchmark>({
  benchmarks,
  label,
  headers,
  locationId,
  openModal,
  renderBenchmark,
  totalLocations,
  tooltip,
  isArea
}: BenchmarkingProps<T>) {
  const headersFiltered = headers.filter(h => h.length > 0)
  const [headerKeys, updateHeaderKey] = useBenchmarkingHeaders(headersFiltered)

  const benchmarksAreNaN: boolean = React.useMemo(() => {
    if (!benchmarks.length) return false

    return benchmarks.every(benchmark => {
      return Object.values(benchmark).every(benchmark => {
        return typeof benchmark === 'number' && isNaN(benchmark)
      })
    })
  }, [benchmarks])

  return (
    <div key="benchmarking" className={classNames('Benchmarking', `Values${headersFiltered.length}`)}>
      <div className={classNames('Heading', 'FirstItem', 'Bold')}>
        <div className="Heading-FirstItem">
          <div className={tooltip ? 'Heading-label' : ''}>
            Benchmarking {label && '-'} {label}
            <span>
              {tooltip && (
                <Tooltip tooltipText={tooltip} className="IconWrapper">
                  <InformationIndicator className="Icon" fill={undefined} />
                </Tooltip>
              )}
            </span>
          </div>
        </div>
      </div>
      {headersFiltered.map((header, index) => (
        <BenchmarkingHeading
          key={index}
          className="Heading"
          item={header}
          onValueChanged={val => updateHeaderKey(val, index)}
        />
      ))}
      <div />
      <div className="Separator" />
      {benchmarksAreNaN ? (
        <div className="NoDataViewContainer">
          <NoDataViewSmall />
        </div>
      ) : benchmarks.length > 0 && headerKeys.length > 0 ? (
        benchmarks.map(benchmark =>
          renderBenchmark(benchmark, headerKeys, {
            Bold: benchmark.id === locationId
          })
        )
      ) : (
        <LoadingSkeleton />
      )}
      {!benchmarksAreNaN &&
        benchmarks.length > 0 &&
        headerKeys.length > 0 &&
        totalLocations > 0 &&
        benchmarks.length <= totalLocations && (
          <div className="SeeMore FirstItem" onClick={openModal}>
            <ArrowIcon angle={90} width={24} height={24} color={colours.offWhite3} />
            <div className="SeeMore Link">
              View all {totalLocations} {locationId === 'ALL' ? 'countries' : isArea ? 'market areas' : 'sites'}
            </div>
          </div>
        )}
    </div>
  )
}

interface BenchmarkingHeadingProps<T extends KPIBenchmark> {
  className: string
  item: HeadingItem<T>

  onValueChanged(key: keyof T): void
}

function BenchmarkingHeading<T extends KPIBenchmark>({ className, item, onValueChanged }: BenchmarkingHeadingProps<T>) {
  const [selected, setSelected] = React.useState('')
  const [isOpen, setOpen] = React.useState(false)
  React.useEffect(() => {
    setSelected(item.length > 0 ? item[0].name : '')
  }, [JSON.stringify(item)])

  const selectedItem = React.useMemo(() => {
    return item.find(header => header.name === selected)
  }, [selected])

  const valueChanged = (val: string) => {
    const key = item.find(i => i.name === val)?.key
    key && onValueChanged(key)
    setSelected(val)
  }

  return (
    <>
      <div className={className} onClick={_ => setOpen(!isOpen)}>
        {item.length > 1 ? (
          <TimeRangeSelector
            className="Heading"
            options={item.map(i => i.name)}
            currentValue={selected}
            onValueChanged={valueChanged}
          />
        ) : item.length > 0 ? (
          item[0].name
        ) : (
          ''
        )}
        {!selectedItem?.description ? null : (
          <Tooltip className="IconWrapper" position="bottom" tooltipText={selectedItem.description}>
            <InformationIndicator className="Icon" />
          </Tooltip>
        )}
      </div>
    </>
  )
}

interface BenchmarkingModalProps<T extends KPIBenchmark> {
  benchmarks: T[]
  closeFn: () => void
  footerBenchmark: T
  headers: HeadingItem<T>[]
  isOpen: boolean
  locationId: string
  renderBenchmark: RenderBenchmarkType<T>
  sortBy: keyof T | ((benchmark: T) => number)
  sortDirection: 'asc' | 'desc'
  title: string
  isArea?: boolean
}

export function BenchmarkingModal<T extends KPIBenchmark>({
  benchmarks,
  closeFn,
  footerBenchmark,
  headers,
  isOpen,
  locationId,
  renderBenchmark,
  sortBy,
  sortDirection,
  title,
  isArea
}: BenchmarkingModalProps<T>) {
  const [headerKeys, updateHeaderKey] = useBenchmarkingHeaders(headers)

  return (
    <SkapaModal visible={isOpen} handleCloseBtn={closeFn}>
      <Sheets size="large" header={<ModalHeader title={`Benchmarking - ${title}`} />} footer={null}>
        <ModalBody className={classNames('BenchmarkingModalBody', `Values${headers.length}`)}>
          <>
            <div className={classNames('BenchmarkingHeading', 'FirstItem')}>
              {locationId === 'ALL' ? 'Country' : isArea ? 'Market Area' : 'Site'}
            </div>
            {headers.map((header, index) =>
              header.length > 1 ? (
                <BenchmarkingHeading
                  key={header.map(h => h.name).join()}
                  className="BenchmarkingHeading"
                  item={header}
                  onValueChanged={val => updateHeaderKey(val, index)}
                />
              ) : header.length > 0 ? (
                <div key={header.map(h => h.name).join()} className="BenchmarkingHeading">
                  {header[0].name}
                </div>
              ) : (
                ''
              )
            )}
            <div />
            {benchmarks
              .sort((a, b) => {
                const aValue = isFunction(sortBy) ? sortBy(a) : Number(a[sortBy])
                const bValue = isFunction(sortBy) ? sortBy(b) : Number(b[sortBy])
                return sortDirection === 'desc' ? bValue - aValue : aValue - bValue
              })
              .map((benchmark, i) =>
                renderBenchmark(benchmark, headerKeys, { Bold: benchmark.id === locationId, FirstCol: i === 0 }, true)
              )}
            {renderBenchmark(footerBenchmark, headerKeys, 'BenchmarkingFooter', true)}
          </>
        </ModalBody>
      </Sheets>
    </SkapaModal>
  )
}

interface CardRowProps {
  className?: string
  disableWidthClass?: boolean
}

export const CardRow: React.FC<CardRowProps> = ({ children, className, disableWidthClass }) => (
  <div className={classNames('CardRow', className)}>
    {React.Children.map(children, card => {
      if (React.isValidElement(card)) {
        const isBenchmarking = card.key === 'benchmarking'
        const isPerformance = card.key === 'goals'

        return (
          <div
            className={classNames(
              'Card',
              { Benchmarking__width: !disableWidthClass && isBenchmarking },
              { KPIPerformance__width: !disableWidthClass && isPerformance }
            )}
          >
            {card}
          </div>
        )
      }

      return null
    })}
  </div>
)

interface DataSourceAndModalButtonProps {
  dataSource?: string
  lastUpdated?: string
  updateFrequency?: string
  onClick: () => void
  isCustomer?: boolean
  onClickHandler?: () => void
}

export const DataSourceAndModalButton: React.FC<DataSourceAndModalButtonProps> = ({
  dataSource,
  lastUpdated,
  updateFrequency = 'monthly',
  onClick,
  isCustomer,
  onClickHandler
}) => (
  <div className="DataSourceAndModalButton">
    <div className="DataSource">
      {dataSource && <>Based on {dataSource}.</>}
      {!isCustomer && <>Updated {updateFrequency}</>}
      <br />
      {lastUpdated && `Latest update ${lastUpdated}.`}
    </div>
    {isCustomer && (
      <Tooltip tooltipText={'Explore more tools for deeper data analysis'} className="resourse-icon__tooltip">
        <div className="resourse-icon__wrapper" onClick={() => onClickHandler && onClickHandler()}>
          {' '}
          <Resourse />{' '}
        </div>
      </Tooltip>
    )}
    <Button text="What does this KPI measure?" onClick={onClick} small />
  </div>
)

export interface KPI {
  key: string
  value: string | string[]
  keyClassNames?: string[]
  valueClassNames?: string[]
  unit?: string
  tooltip?: string
  colorStyle?: string
  colorClass?: string
  isUnitRegular?: boolean
}

interface KPIPerformanceProps {
  heading?: string
  kpis: KPI[]
  units: string[]
  classes?: string
  noLastClass?: boolean
}

interface KPIPerformanceLineProps {
  kpi: KPI
  isLast: boolean
}

const KPIPerformanceLine: React.FC<KPIPerformanceLineProps> = ({ kpi, isLast }) => (
  <React.Fragment key={kpi.key}>
    <div className={classNames('FirstItem', kpi.keyClassNames, { skipLast: isLast })}>
      {kpi.key === 'upstreamEnergy' ? 'Upstream energy' : kpi.key}
    </div>
    {isArray(kpi.value) ? (
      <>
        {kpi.unit && (
          <div className={classNames('Right', 'FOO', kpi.valueClassNames, { LastRow: isLast })}>
            <span className="GoalUnit">{kpi.unit} </span>
          </div>
        )}
        {kpi.value.map((v, n) => (
          <div key={v} className={classNames('Right', n === 0 ? kpi.colorStyle : '')}>
            {kpi.unit === '%' ? formatRelativeNumber(Number(v)) : v}
          </div>
        ))}
      </>
    ) : (
      <div
        className={classNames('Right', kpi.valueClassNames, { LastRow: isLast }, kpi.colorClass ? kpi.colorClass : '')}
      >
        {kpi.unit && (
          <span
            className={classNames('GoalUnit', kpi.unit === 'building kWh/m²' || kpi.isUnitRegular ? 'Regular' : '')}
          >
            {kpi.unit}{' '}
          </span>
        )}
        {kpi.value ? (kpi.unit?.includes('%') ? formatRelativeNumber(Number(kpi.value)) : kpi.value) : 'N/A'}
      </div>
    )}
    <div />
  </React.Fragment>
)

export const KPIPerformance: React.FC<KPIPerformanceProps> = ({ heading, kpis, units, classes, noLastClass }) => (
  <div className={classNames('KPIPerformance', `KPIPerformance-${units.length}-col`, classes)}>
    <div className="Heading FirstItem Bold">{heading ?? 'Goals and performance'}</div>
    {units.map(unit => (
      <div key={unit} className="Heading Right">
        {unit}
      </div>
    ))}
    <div />
    <div className="Separator" />
    {kpis && kpis.length > 0 ? (
      kpis.map((kpi, index) => (
        <KPIPerformanceLine key={index} kpi={kpi} isLast={!noLastClass && index === kpis.length - 1} />
      ))
    ) : (
      <LoadingSkeleton />
    )}
  </div>
)

interface MainCardProps {
  title?: string
  titleExtantion?: string
  description?: string
  subtitle?: string | JSX.Element
  coWorkers?: boolean
}

interface ChartData {
  domain: Date[]
  dateFormat: DateFormat
  series: Serie[]
  isLineChart: boolean
  unit: string
}

export const MainCard: React.FC<MainCardProps> = ({
  title,
  titleExtantion,
  description,
  subtitle,
  children,
  coWorkers
}) => {
  const graphRef = React.useRef<HTMLDivElement>(null)
  const { currentLocation } = useLocations()
  const [domainAndSeries, setDomainAndSeries] = React.useState<ChartData>({
    domain: [],
    dateFormat: 'month',
    series: [],
    isLineChart: true,
    unit: ''
  })

  const exportTooltipText =
    domainAndSeries.domain.length > 0 && domainAndSeries.series.length > 0
      ? 'Download chart and data as Powerpoint presentation'
      : graphRef.current != null
      ? 'Download chart as PNG image'
      : ''

  return (
    <div className="MainCard">
      {title && (
        <div className="CardHeading FirstItem">
          {title} {titleExtantion && <span className="TitleExtantion">{titleExtantion}</span>}
          {description && (
            <Tooltip tooltipText={description}>
              <InformationIndicator className="Icon" fill={coWorkers ? '#767676' : undefined} />
            </Tooltip>
          )}
          {coWorkers && (
            <>
              <Tooltip tooltipText="Open Co-worker view configurator">
                <Link page={Route.InstoreConfiguratorPage}>
                  <CoWorkerScreenIcon className="Icon" />
                </Link>
              </Tooltip>
              <Tooltip tooltipText={exportTooltipText}>
                <ExportIcon
                  className="Icon"
                  onClick={_ => {
                    if (domainAndSeries.domain.length > 0 && domainAndSeries.series.length > 0) {
                      exportGraphAsPptx(
                        `${title} - ${getLocationLabel(currentLocation)}`,
                        domainAndSeries.series,
                        domainAndSeries.domain,
                        domainAndSeries.dateFormat,
                        domainAndSeries.isLineChart,
                        domainAndSeries.unit
                      )
                    } else if (graphRef.current != null) {
                      exportGraphAsPng(graphRef.current, `${title} - ${getLocationLabel(currentLocation)}`)
                    }
                  }}
                />
              </Tooltip>
            </>
          )}
          <div className="Spacer" />
          {subtitle && <div className="Subtitle">{subtitle}</div>}
        </div>
      )}
      <ChartDataContext.Provider
        value={{
          setValues: (domain: Date[], dateFormat, series: Serie[], isLineChart: boolean, unit: string) =>
            setDomainAndSeries({ domain, dateFormat, series, isLineChart, unit })
        }}
      >
        <div className="ExploreGraphContainer" ref={graphRef}>
          {children}
        </div>
      </ChartDataContext.Provider>
    </div>
  )
}

interface Stripe {
  title: string
  subtitle?: string
  isBeta?: boolean
  setModalDownloadState?: (isOpen: boolean) => void
  isLoading?: boolean
  lastUpdated?: string
}

export const Stripe: React.FC<Stripe> = ({
  title,
  subtitle,
  children,
  isBeta,
  setModalDownloadState,
  isLoading,
  lastUpdated
}) => (
  <div className="Stripe">
    <div className="Title">
      <Show when={Boolean(isBeta)}>
        <BetaLabel />
      </Show>
      {lastUpdated ? (
        <span className="DataSource-customer">
          <span className="DataSource-customer__title">{title} </span>
          <span>Updated monthly. Latest update {lastUpdated}.</span>
        </span>
      ) : (
        title
      )}
      {setModalDownloadState && (
        <Tooltip tooltipText={'Push to download benchmark data'}>
          {!isLoading ? (
            <ArrowDownToBase classes="arrowIcon" onClick={() => setModalDownloadState(true)} />
          ) : (
            <div className="Loading-square" />
          )}
        </Tooltip>
      )}
      <span className="Regular">{subtitle}</span>
    </div>
    {children}
  </div>
)

interface HasId {
  id: string
}

export function sortBenchmarks<T extends HasId>(benchmarks: T[], sortKey: keyof T, locationId: string): T[] {
  const selectedLocation = benchmarks.find(b => b.id === locationId)
  if (!selectedLocation) {
    return benchmarks
  }
  return benchmarks
    .map(b => ({ ...b, diff: Math.abs(Number(b[sortKey]) - Number(selectedLocation[sortKey])) }))
    .sort((lhs, rhs) => {
      if (lhs.id === locationId) {
        return -1
      }
      if (rhs.id === locationId) {
        return 1
      }
      return lhs.diff - rhs.diff
    })
}
