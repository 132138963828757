import React, { Fragment } from 'react'
import { TopBar } from '../../../components/TopBar'
import { Route } from '../../../routes'
import { PageHeader } from '../../../components/PageHeader'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  HeadingItem,
  KPIPerformance,
  RenderBenchmarkType,
  Stripe
} from '../../../components/KPIPage'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { CustomerTravelContext, CustomerTravelProvider } from './CustomerTravelProvider'
import { Show } from '../../../components/Conditions/Show'
import { CustomerTravelBenchmark } from '../../../../api/src/types/benchmark'
import classNames from 'classnames'
import { getLocationId, isSiteId } from '../../../components/Utils/utils'
import { useCustomerTravelBenchmarkData } from './data/footprint'
import { useLocations } from '../../../context'
import { formatAbsoluteNumber, formatRelativeNumber, multiFormatNumber } from '../../../components/Utils/format'
import { CustomerTravelMonthKPI } from './CustomerTravelMonthKPI'
import { FootprintByTransportTypeKPI } from './FootprintByTransportTypeKPI'
import { AnalyticsToolsModal, KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PeopleByTransportTypeKPI } from './PeopleByTransportType'
import { FootprintYtdKPI } from './FootprintYtdKPI'
import { CustomerDistanceKPI } from './CustomerDistanceKPI'
import InlineMessage from '@ingka/inline-message'
import { useDynamicLabel } from './data/useDynamicLabel'
import { NoDataView } from '../../../components/BaseGraphs/NoDataView'

const CustomerTravelImpl = () => {
  useDocumentTitle('Customer Travel')

  const [benchmarkingModalOpen, setBenchmarkingModalOpen] = React.useState(false)
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })

  const personLabel = useDynamicLabel()

  const { currentLocation } = useLocations()
  const { benchmarks, locationId, benchmarkingFY, lastUpdated, noData } = React.useContext(CustomerTravelContext)
  const { selectedLocationBenchmark, comparisonBenchmarks, countryBenchmark } = useCustomerTravelBenchmarkData()
  const [modalToolsState, setModalToolsState] = React.useState<boolean>(false)

  const benchmarksWithoutCurrentLocation = React.useMemo(() => {
    const isSite = isSiteId(locationId)
    return benchmarks?.filter(b => b.id !== locationId && (isSite ? b.id.length >= 3 : true)) ?? []
  }, [JSON.stringify(benchmarks)])

  // Hooks end ----

  const WarningMessage = (
    <div className="InlineMessageWrapper">
      <InlineMessage
        body="Note that the scope of this measurement is customers travelling to stores for retail, and visitors travelling to Ingka Centres meeting places."
        variant="cautionary"
      />
    </div>
  )

  const benchmarkHeaders: HeadingItem<CustomerTravelBenchmark>[] = [
    [{ name: `FY${Number(benchmarkingFY) - 2001} YTD`, key: 'previousYtd' }],
    [{ name: `FY${Number(benchmarkingFY) - 2000} YTD`, key: 'currentYtd' }]
  ]

  const renderBenchmark: RenderBenchmarkType<CustomerTravelBenchmark> = (benchmark, keys, classes) => {
    const prevFYValue = benchmark[keys[0]]
    const currFYValue = benchmark[keys[1]]

    return (
      <Fragment key={benchmark.id}>
        <div className={classNames('FirstItem', classes)}>{benchmark.label}</div>
        <div className={classNames('Right', classes)}>{multiFormatNumber(prevFYValue)}</div>
        <div className={classNames('Right', classes)}>{multiFormatNumber(currFYValue)}</div>
        <div />
      </Fragment>
    )
  }

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.CustomerTravelKPIPage} useInFlexLayout />
      <PageHeader className="ClimateFootprintHeader" route={Route.CustomerTravelKPIPage}></PageHeader>
      <div className="PageContent">
        <Stripe title="Climate Footprint — Customer Travel" isBeta lastUpdated={lastUpdated}>
          <DataSourceAndModalButton
            onClick={() => setModalState({ isOpen: true, page: Route.CustomerTravelKPIPage })}
            isCustomer
            onClickHandler={() => {
              setModalToolsState(true)
            }}
          />
        </Stripe>
        {WarningMessage}
        <Show when={noData}>
          <NoDataView />
        </Show>
        <Show when={!noData}>
          <>
            <CardRow className="BenchmarkingAndGoals" disableWidthClass>
              <Benchmarking
                key="benchmarking"
                benchmarks={
                  comparisonBenchmarks
                    ? [...(selectedLocationBenchmark ? [selectedLocationBenchmark] : []), ...comparisonBenchmarks]
                    : []
                }
                label={`kg CO2e/${personLabel.single}`}
                headers={benchmarkHeaders}
                locationId={locationId}
                openModal={() => setBenchmarkingModalOpen(true)}
                renderBenchmark={renderBenchmark}
                totalLocations={benchmarksWithoutCurrentLocation.length ?? 0}
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={['YTD']}
                kpis={
                  !selectedLocationBenchmark
                    ? []
                    : [
                        {
                          key: 'Total CO2e from customer travel',
                          unit: 'tonnes CO2e',
                          value: selectedLocationBenchmark?.total_footprint
                            ? formatAbsoluteNumber(selectedLocationBenchmark?.total_footprint / 1000)
                            : ''
                        },
                        {
                          key: 'Average Distance Travelled',
                          unit: `km/${personLabel.single}`,
                          value: selectedLocationBenchmark?.distance_person
                            ? formatAbsoluteNumber(selectedLocationBenchmark?.distance_person)
                            : ''
                        },
                        {
                          key: `Average CO2e per ${personLabel.single}`,
                          unit: `kg CO2e/${personLabel.single}`,
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold'],
                          isUnitRegular: true,
                          value: selectedLocationBenchmark?.currentYtd
                            ? formatRelativeNumber(selectedLocationBenchmark?.currentYtd)
                            : ''
                        }
                      ]
                }
              />
            </CardRow>
            <CustomerTravelMonthKPI />
            <CardRow>
              <FootprintByTransportTypeKPI />
              <PeopleByTransportTypeKPI />
            </CardRow>
            <CardRow>
              <FootprintYtdKPI />
              <CustomerDistanceKPI />
            </CardRow>
          </>
        </Show>
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      <Show when={Number(benchmarks?.length) > 0}>
        <BenchmarkingModal
          benchmarks={benchmarksWithoutCurrentLocation}
          closeFn={() => setBenchmarkingModalOpen(false)}
          footerBenchmark={countryBenchmark}
          headers={benchmarkHeaders}
          isOpen={benchmarkingModalOpen}
          locationId={getLocationId(currentLocation)}
          renderBenchmark={renderBenchmark}
          sortBy="currentYtd"
          sortDirection="asc"
          title={`kg CO2e/${personLabel.single}`}
        />
      </Show>
      <div className="KPIPage__modal-header">
        <AnalyticsToolsModal
          lastUpdated={lastUpdated}
          modalState={modalToolsState}
          onClose={() => setModalToolsState(false)}
        />
      </div>
    </div>
  )
}

export const CustomerTravel = () => {
  return (
    <CustomerTravelProvider>
      <CustomerTravelImpl />
    </CustomerTravelProvider>
  )
}
