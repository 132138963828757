import React from 'react'
import {
  BenchmarkResponse,
  ClimateResultsBenchmark,
  DeliveryResultsBenchmark,
  EnergyEfficiencyBenchmark,
  FairAndEqualBenchmark,
  FoodIngredientsBenchmark,
  PlanetBenchmarkResult,
  ProfitBenchmarkResult,
  WaterEfficiencyBenchmark
} from '../../../../api/src/types/benchmark'
import { getZeroWasteBenchmarks, ZeroWasteBenchmark } from '../../KPIPages/ZeroWaste/ZeroWasteKPIPage'
import { kpiNames, TableRowKey } from '../../../components/Benchmark/BenchmarkTableRow'
import { GoalsResponse } from '../../../../api/src/types/goals'
import { analyticsEvent } from '../../../components/Utils/analytics'
import { useDataAvailabilityContext, useLocations } from '../../../context'
import { useSharedSelections } from '../../../SharedSelections'
import {
  getChangeMakersBenchmarking,
  getCustomerDeliveriesBenchmarking,
  getEnergyEfficiencyBenchmarking,
  getFoodIngredientsBenchmarks,
  getGoals,
  getPeopleImpactedBenchmarks,
  getPlanetBenchmarking,
  getProfitBenchmarking,
  getTotalFootprint,
  getWaterEfficiencyBenchmarking
} from '../../../lib/APIClient'
import { getCountry, getLocationOrDefault, isCluster } from '../../../components/Utils/utils'
import { CurrMonth } from '../../../components/Utils/dates'

export type PreviousFyTimeRange = 'YTD' | 'Result' | CurrMonth
export type CurrentFyTimeRange = 'YTD' | 'Rolling 12 mos'

type State = {
  prevTimeRange: PreviousFyTimeRange
  currTimeRange: CurrentFyTimeRange

  planet: BenchmarkResponse<PlanetBenchmarkResult> | null
  profit: BenchmarkResponse<ProfitBenchmarkResult> | null
  energyEfficiency: EnergyEfficiencyBenchmark[] | null
  socialImpact: FairAndEqualBenchmark[] | null
  waterEfficiency: WaterEfficiencyBenchmark[] | null
  zeroWaste: ZeroWasteBenchmark[] | null
  foodIngredients: FoodIngredientsBenchmark[] | null
  customerDeliveries: DeliveryResultsBenchmark[] | null
  changeMakers: FairAndEqualBenchmark[] | null
  expandedKey?: TableRowKey
  goals?: GoalsResponse
  goalsNextFy?: GoalsResponse
  goalsPrevFy?: GoalsResponse
}

type BenchmarkingContextType = {
  data: State
  dispatch: React.Dispatch<Action>
  toggleExpanded: (row: TableRowKey) => void
}

type Action =
  | { type: 'prevTimeRange'; payload: PreviousFyTimeRange }
  | { type: 'currTimeRange'; payload: CurrentFyTimeRange }
  | { type: 'planet'; payload: BenchmarkResponse<PlanetBenchmarkResult> | null }
  | { type: 'profit'; payload: BenchmarkResponse<ProfitBenchmarkResult> | null }
  | { type: 'energyEfficiency'; payload: EnergyEfficiencyBenchmark[] | null }
  | { type: 'socialImpact'; payload: FairAndEqualBenchmark[] | null }
  | { type: 'waterEfficiency'; payload: WaterEfficiencyBenchmark[] | null }
  | { type: 'zeroWaste'; payload: ZeroWasteBenchmark[] | null }
  | { type: 'foodIngredients'; payload: FoodIngredientsBenchmark[] | null }
  | { type: 'customerDeliveries'; payload: ClimateResultsBenchmark[] | null }
  | { type: 'changeMakers'; payload: FairAndEqualBenchmark[] | null }
  | { type: 'expandedKey'; payload?: TableRowKey }
  | { type: 'goals'; payload?: GoalsResponse }
  | { type: 'goalsNextFy'; payload?: GoalsResponse }
  | { type: 'goalsPrevFy'; payload?: GoalsResponse }

const initialData: State = {
  prevTimeRange: 'YTD',
  currTimeRange: 'YTD',
  planet: null,
  profit: null,
  energyEfficiency: null,
  socialImpact: null,
  waterEfficiency: null,
  foodIngredients: null,
  customerDeliveries: null,
  changeMakers: null,
  zeroWaste: []
}

export const BenchmarkingContext = React.createContext<BenchmarkingContextType | null>(null)

export const BenchmarkingProvider: React.FC<React.PropsWithChildren<unknown>> = props => {
  const [data, dispatch] = React.useReducer(benchmarkingReducer, initialData)
  const { currentLocation } = useLocations()
  const [{ func, functionArea }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const locationId = getLocationOrDefault()
  const { locations } = useLocations()

  const countryCodes = currentLocation.isCluster
    ? currentLocation.cluster.countryCodes
    : [currentLocation.location.countryCode]

  const wasteSelector = {
    locationId,
    countryCodes,
    site: 'ALL',
    func,
    start_fy: dataAvailability?.planetPreviousFY,
    end_fy: dataAvailability?.planetCurrentFY,
    isOld: true
  }

  React.useEffect(() => {
    dispatch({ type: 'planet', payload: null })
    dispatch({ type: 'socialImpact', payload: null })

    getPlanetBenchmarking(countryCodes, func, locationId).then(payload => dispatch({ type: 'planet', payload }))
    getEnergyEfficiencyBenchmarking(countryCodes[0], func, dataAvailability?.energyCurrentFY || 2025).then(payload =>
      dispatch({
        type: 'energyEfficiency',
        payload
      })
    )
    getCustomerDeliveriesBenchmarking(countryCodes[0]).then(payload =>
      dispatch({
        type: 'customerDeliveries',
        payload
      })
    )
    getWaterEfficiencyBenchmarking(countryCodes[0], func).then(payload =>
      dispatch({
        type: 'waterEfficiency',
        payload
      })
    )
    getChangeMakersBenchmarking(
      currentLocation.isCluster ? currentLocation.cluster.countryCodes : [currentLocation.location.countryCode],
      func,
      dataAvailability?.socialImpactCurrentFY ?? 0,
      dataAvailability?.socialImpactPreviousFY ?? 0
    ).then(({ data }) => dispatch({ type: 'changeMakers', payload: data }))
    getPeopleImpactedBenchmarks(
      currentLocation.isCluster ? currentLocation.cluster.countryCodes : [currentLocation.location.countryCode],
      func,
      dataAvailability?.socialImpactCurrentFY ?? 0,
      dataAvailability?.socialImpactPreviousFY ?? 0
    ).then(({ data }) => dispatch({ type: 'socialImpact', payload: data }))
    getFoodIngredientsBenchmarks(locationId).then(payload => dispatch({ type: 'foodIngredients', payload }))

    if (!currentLocation.isCluster && functionArea !== 'centres') {
      dispatch({ type: 'profit', payload: null })
      getProfitBenchmarking(currentLocation.location.countryCode, func, locationId).then(payload =>
        dispatch({
          type: 'profit',
          payload
        })
      )
    }
  }, [JSON.stringify(currentLocation), JSON.stringify(func), dataAvailability, locationId])

  React.useEffect(() => {
    dispatch({ type: 'zeroWaste', payload: null })
    dispatch({ type: 'goals' })

    !isCluster(locationId) &&
      getGoals(getCountry(locationId, locations).countryCode, func, dataAvailability?.planetCurrentFY || 2025).then(
        goals => {
          dispatch({
            type: 'goals',
            payload: goals
          })

          // Remove in future goals request dependency
          getTotalFootprint(wasteSelector, ['waste']).then(footprintData => {
            const [payload] = getZeroWasteBenchmarks(
              footprintData.data,
              goals,
              (dataAvailability?.planetCurrentFY ?? 2000) - 2000,
              locationId
            )
            dispatch({ type: 'zeroWaste', payload })
          })
        }
      )
  }, [locationId, dataAvailability?.planetCurrentFY, JSON.stringify(wasteSelector)])

  const toggleExpanded = (row: TableRowKey) => {
    const isExpand = row !== data.expandedKey

    if (isExpand) {
      analyticsEvent({
        category: 'Benchmarking',
        action: 'Expand',
        label: kpiNames[row]
      })
    }
    dispatch({ type: 'expandedKey', payload: isExpand ? row : undefined })
  }

  return (
    <BenchmarkingContext.Provider value={{ data, dispatch, toggleExpanded }}>
      {props.children}
    </BenchmarkingContext.Provider>
  )
}

const benchmarkingReducer = (data = initialData, action: Action) => {
  return {
    ...data,
    [action.type]: action.payload
  }
}
