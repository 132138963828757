import { endOfMonth, format, getMonth } from 'date-fns'
import { Dictionary, groupBy, isNil, maxBy, sumBy, uniq } from 'lodash'
import React from 'react'

import { FinancialYearChartContainer } from '../../../components/BaseGraphs/FinancialYearChartContainer'
import { PeopleImpacted, FairAndEqualBenchmark, SiteFunction, GoalsResponse } from '../../../../api/src/common-types'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { centreSiteFunctions, retailSiteFunctions } from '../../../components/LocationSearch'
import { TopBar } from '../../../components/TopBar'
import { getFinancialYear } from '../../../components/Utils/dates'
import { formatAbsoluteNumber, formatPercentage, formatRelativeNumber } from '../../../components/Utils/format'
import {
  getCluster,
  getCountry,
  getGoalsForLocation,
  getLocationOrDefault,
  getLocationSelector,
  isCluster,
  isCountryCode
} from '../../../components/Utils/utils'
import { getGoals, getPeopleImpacted, getPeopleImpactedBenchmarks, getSocialImpactGoal } from '../../../lib/APIClient'
import { useLocations, useDataAvailabilityContext } from '../../../context'
import { useSharedSelections } from '../../../SharedSelections'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'

import '../KPIPage.scss'
import './Tooltip.scss'
import colours from '../../../Colours.module.scss'
import styles from './SocialImpactKPIPage.module.scss'

import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  KPI,
  KPIPerformance,
  HeadingItem,
  MainCard,
  RenderBenchmarkType,
  sortBenchmarks,
  Stripe
} from '../../../components/KPIPage'
import { PageHeader } from '../../../components/PageHeader'
import { SocialImpactGraphSideBarCards } from '../../../components/GraphSideBarCards'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { SummaryItem } from '../../../components/BaseGraphs/Tooltip'
import { Route } from '../../../routes'
import { getSocialImpact } from '../../Snapshot/Benchmarking/hooks/useSocialData'
import classNames from 'classnames'
import { getCurrentPeriod } from '../../../components/Utils/dates'
import { DataPoint } from '../../../components/BaseGraphs/ChartContainer'
import InlineMessage from '@ingka/inline-message'

interface SocialImpactBenchmark {
  id: string
  label: string
  selectable: boolean
  previousFyResult: number
  previousFyYtd: number
  currentFyYtd: number | null
  goalPrevFY: number | null
  goal: number | null
  goalNextFY: number | null
  ratio: number | string
}

function getRenderBenchmark(isGoalsDefined: boolean, isFunctionAreaCentres: boolean) {
  const renderFairAndEqualBenchmark: RenderBenchmarkType<SocialImpactBenchmark> = (benchmark, keys, classes) => (
    <React.Fragment key={benchmark.id}>
      <div className={classNames('FirstItem', classes)}>{benchmark.label}</div>
      {/*<div className={classNames('Right', classes, 'Black')}>*/}
      {/*  {benchmark[keys[0]] ? formatAbsoluteNumber(Math.round(benchmark[keys[0]] as number)) : 'N/A'}*/}
      {/*</div>*/}

      <div
        className={classNames(
          'Right',
          classes,
          'YTD',
          benchmark.currentFyYtd && benchmark.goal
            ? benchmark.currentFyYtd > benchmark.goal
              ? 'OnTrack'
              : 'NotOnTrack'
            : 'Black'
        )}
      >
        {benchmark.currentFyYtd ? formatAbsoluteNumber(Math.round(benchmark.currentFyYtd)) : 'N/A'}
      </div>
      {!isFunctionAreaCentres && (
        <div className={classNames('Right', classes, 'YTD', 'Black')}>
          {benchmark.ratio ? formatRelativeNumber(Number(benchmark.ratio)) : 'N/A'}
        </div>
      )}
      {isGoalsDefined && (
        <>
          <div className={classNames('Right', classes)}>
            {benchmark.goalPrevFY ? formatAbsoluteNumber(benchmark.goalPrevFY) : 'N/A'}
          </div>
          <div className={classNames('Right', classes)}>
            {benchmark.goal ? formatAbsoluteNumber(benchmark.goal) : 'N/A'}
          </div>
          <div className={classNames('Right', classes)}>
            {benchmark.goalNextFY ? formatAbsoluteNumber(benchmark.goalNextFY) : 'N/A'}
          </div>
        </>
      )}
      <div />
    </React.Fragment>
  )
  return renderFairAndEqualBenchmark
}

// export const getPositivelyImpacted = ({ improved, transformed }: PeopleImpacted) => improved + transformed

export const isSameFairAndEqualLocation = (locationId: string) => (x: FairAndEqualBenchmark) =>
  locationId?.length > 3 ? x.siteId === locationId : x.countryCode === locationId

const getBenchmark = (
  rawBenchmarks: FairAndEqualBenchmark[],
  goals: GoalsResponse | undefined,
  currentFy: number,
  locationsRatio: Record<string, number>,
  locationId: string
) => {
  const locationName =
    rawBenchmarks?.find(isSameFairAndEqualLocation(locationId))?.[locationId.length > 3 ? 'siteName' : 'countryName'] ??
    'Global (All Locations)'

  const benchmarksForLocation =
    (locationId === 'ALL' ? rawBenchmarks : rawBenchmarks?.filter(isSameFairAndEqualLocation(locationId))) ?? []

  const latestPeriod =
    maxBy(
      rawBenchmarks?.filter(x => x.fiscalYear === currentFy - 2000),
      'fiscalPeriod'
    )?.fiscalPeriod ?? 12

  const ratio = locationsRatio[locationId]

  const prevResult = getSocialImpact(
    'Result',
    'YTD',
    benchmarksForLocation,
    locationName,
    currentFy,
    latestPeriod,
    false,
    undefined
  )

  const prevYtd = getSocialImpact(
    'YTD',
    'YTD',
    benchmarksForLocation,
    locationName,
    currentFy,
    latestPeriod,
    false,
    undefined
  )

  return {
    id: locationId,
    label: locationName,
    selectable: prevYtd.selectable,
    previousFyResult: prevResult.previousFy,
    previousFyYtd: prevYtd.previousFy,
    currentFyYtd: prevResult.comparisonValue,
    goalPrevFY: getGoalsForLocation(locationId, goals)?.socialImpactGoalPrevFY ?? null,
    goal: getGoalsForLocation(locationId, goals)?.socialImpactGoal ?? null,
    goalNextFY: getGoalsForLocation(locationId, goals)?.socialImpactGoalNextFY ?? null,
    ratio
  }
}

export const SocialImpactKPIPage = () => {
  const page = Route.SocialImpactKPIPage
  useDocumentTitle('Social Impact')

  const locationId = getLocationOrDefault()
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const { dataAvailability } = useDataAvailabilityContext()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [peopleImpacted, setPeopleImpacted] = React.useState<PeopleImpacted[]>()
  const [actualPeriodDate, setActualPeriodDate] = React.useState<string>()
  const [byFinancialYear, setByFinancialYear] = React.useState<Dictionary<PeopleImpacted[]>>()
  const [goal, setGoal] = React.useState<number | null>(null)
  const [{ functionArea }] = useSharedSelections()
  const { currentLocation, clusters, locations } = useLocations()
  const [rawBenchmarks, setRawBenchmarks] = React.useState<FairAndEqualBenchmark[] | null>(null)
  const [locationsRatio, setLocationsRatio] = React.useState({})
  const [goals, setGoals] = React.useState<GoalsResponse>()

  const currFyName = `FY${(dataAvailability?.socialImpactCurrentFY ?? 0) - 2000}` as const
  const prevFyName = `FY${(dataAvailability?.socialImpactPreviousFY ?? 0) - 2000}` as const

  const currFyData = byFinancialYear?.[currFyName]
  const prevFyData = byFinancialYear?.[prevFyName]

  const func: SiteFunction[] =
    functionArea === 'ingka' ? ['ALL'] : functionArea === 'retail' ? retailSiteFunctions : centreSiteFunctions

  const locationSelector = getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes)

  React.useEffect(() => {
    if (dataAvailability) {
      setLastUpdated('')
      setByFinancialYear(undefined)
      setPeopleImpacted(undefined)
      setGoal(null)
      setRawBenchmarks(null)

      getPeopleImpacted({
        ...locationSelector,
        func,
        isOld: true,
        start_fy: dataAvailability.socialImpactPreviousFY,
        end_fy: dataAvailability.socialImpactCurrentFY
      }).then(({ lastUpdated, data: peopleImpacted, actualPeriod }) => {
        const byFinancialYear = groupBy(peopleImpacted, ({ readableDate }) => getFinancialYear(readableDate))
        setLastUpdated(format(new Date(lastUpdated), 'dd/MM/yyyy'))
        setByFinancialYear(byFinancialYear)
        setPeopleImpacted(peopleImpacted)
        setActualPeriodDate(actualPeriod)
      })

      getPeopleImpactedBenchmarks(
        locationSelector.countryCodes.length > 0 || currentLocation.isCluster
          ? locationSelector.countryCodes
          : [currentLocation.location.countryCode],
        func,
        dataAvailability.socialImpactCurrentFY,
        dataAvailability.socialImpactPreviousFY
      ).then(({ data, ratio }) => {
        setRawBenchmarks(data)
        setLocationsRatio(ratio)
      })

      if (!isCluster(locationId)) {
        getGoals(getCountry(locationId, locations).countryCode, func, dataAvailability.planetCurrentFY).then(setGoals)
      }

      getSocialImpactGoal(locationId).then(setGoal)
    }
  }, [locationId, JSON.stringify(dataAvailability), JSON.stringify(locationSelector), JSON.stringify(func)])

  const tooltipSummary = (date: Date): SummaryItem[] => {
    if (!currFyData || !prevFyData) return []

    const currValueInd = currFyData.findIndex(value => getMonth(new Date(value.readableDate)) === getMonth(date))
    const currValue = currFyData[currValueInd].strongContribution
      ? sumBy(currFyData.slice(0, currValueInd + 1), 'strongContribution')
      : 0
    const prevValue = sumBy(prevFyData.slice(0, currValueInd + 1), 'strongContribution')
    const diffValue = (currValue - prevValue) / prevValue

    const lastYtdDate = currFyData && new Date(currFyData[currFyData.length - 1]?.readableDate ?? Date.now())

    return date <= endOfMonth(lastYtdDate) && currValue
      ? [
          {
            icon: <span style={{ fontWeight: 700 }}>%</span>,
            title: 'Difference',
            onTrack: Number.isNaN(diffValue) || diffValue === Infinity ? undefined : diffValue > 0,
            value: diffValue === Infinity ? 'N/A' : formatPercentage(diffValue)
          }
        ]
      : []
  }

  const benchmarkLocationIds = uniq(
    rawBenchmarks
      ?.filter(x => x.selectable || (x.countryCode === 'UA' && locationId === 'ALL'))
      .map(x => (locationId === 'ALL' ? x.countryCode : x.siteId))
  )
  const currentLocationBenchmark = getBenchmark(
    rawBenchmarks ?? [],
    goals,
    dataAvailability?.socialImpactCurrentFY ?? 0,
    locationsRatio,
    locationId
  )
  const countryBenchmark = getBenchmark(
    rawBenchmarks ?? [],
    goals,
    dataAvailability?.socialImpactCurrentFY ?? 0,
    locationsRatio,
    currentLocation.isCluster || locationId.length <= 3 ? locationId : currentLocation.location.countryCode
  )

  const benchmarks = benchmarkLocationIds
    .map(
      getBenchmark.bind(null, rawBenchmarks ?? [], goals, dataAvailability?.socialImpactCurrentFY ?? 0, locationsRatio)
    )
    .filter(x => x.selectable)

  const domain = peopleImpacted?.slice(12).map(x => x.readableDate)

  const series =
    byFinancialYear &&
    Object.entries(byFinancialYear).map(([fyName, data]) => ({
      name: fyName,
      color: fyName === currFyName ? colours.darkBlue1 : colours.offWhite1,
      fill: fyName === prevFyName ? colours.grey1 : undefined,
      data: data.reduce<DataPoint[]>((result, current, currentIndex) => {
        const periodDate = new Date(current.readableDate)
        const actualDate = new Date(actualPeriodDate || '')

        if (periodDate > actualDate) return result

        const prevYValue = result[currentIndex - 1]?.y ?? 0

        return [
          ...result,
          {
            x: periodDate,
            y: Number(prevYValue + current.strongContribution)
          }
        ]
      }, [])
    }))

  if (goal && series && domain && domain.length > 0) {
    const goalSerie = {
      id: 'goal',
      name: `${currFyName} Goal`,
      color: colours.lightBlue2,
      fill: undefined,
      data: domain?.map((d, i) => ({
        x: new Date(d),
        y: (Number(goal) / 12) * (i + 1)
      }))
    }

    series.push(goalSerie)
  }

  const currFyHeading = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000} YTD`
  // const prevFyHeading = `FY${(dataAvailability?.socialImpactPreviousFY ?? 2000) - 2000} YTD`
  // const prevFyResultHeading = `FY${(dataAvailability?.socialImpactPreviousFY ?? 2000) - 2000} Result`
  const goalHeading = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000} Goal`
  // const goalHeadingYTD = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000} Goal`
  const goalHeadingYTD = `${currFyName} Goal YTD`
  const goalHeadingNext = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) + 1 - 2000} Goal`
  const goalHeadingPrev = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 1 - 2000} Goal`

  // Let's keep this logic since there was a need once to hide goals column if not defined
  const isGoalsDefinedForBenchmarks = true

  const lastDisplayedDate = [...(series?.find(s => s.name === currFyName)?.data || [])].reverse()[0]?.x
  const period = getCurrentPeriod(lastDisplayedDate)

  const ytdGoal = (Number(goal) / 12) * period
  const isFunctionAreaCentres = functionArea === 'centres'
  const benchmarkingHeadersInitial: HeadingItem<SocialImpactBenchmark>[] = [
    // [
    //   { name: prevFyHeading, key: 'previousFyYtd' },
    //   { name: prevFyResultHeading, key: 'previousFyResult' }
    // ],
    [{ name: currFyHeading, key: 'currentFyYtd' }]
  ]
  const benchmarkingHeaders: HeadingItem<SocialImpactBenchmark>[] = !isFunctionAreaCentres
    ? [
        ...benchmarkingHeadersInitial,
        [
          {
            name: 'Sales Ratio',
            description:
              'Performance measured against turnover (Incl. Food)\nRatio = Number of people positively impacted x Turnover (Incl Food) / 10.000.000',
            key: 'ratio'
          }
        ]
      ]
    : benchmarkingHeadersInitial

  const benchmarkingHeadersWithGoals: HeadingItem<SocialImpactBenchmark>[] = [
    ...benchmarkingHeaders,
    [{ name: goalHeadingPrev, key: 'goalPrevFY' }],
    [{ name: goalHeading, key: 'goal' }],
    [{ name: goalHeadingNext, key: 'goalNextFY' }]
  ]

  const doNotShowGoal = functionArea === 'ingka'

  return (
    <div className="KPIPage">
      <TopBar currentPage={page} />
      <PageHeader className="SocialImpactHeader" route={Route.SocialImpactKPIPage}></PageHeader>
      <div className="PageContent">
        <Stripe title="Social Impact">
          <DataSourceAndModalButton lastUpdated={lastUpdated} onClick={() => setModalState({ isOpen: true, page })} />
        </Stripe>
        <div className="InlineMessageWrapper">
          <InlineMessage
            body="Due to a change in methodology, FY24 and FY25 results cannot be directly compared. As a result, FY24 results will remain hidden until an archived data page featuring prior years' results (currently under development) is made available."
            variant="cautionary"
          />
        </div>
        <CardRow className="BenchmarkingAndGoals SocialImpactBenchmarking">
          <Benchmarking
            key="benchmarking"
            benchmarks={
              !rawBenchmarks
                ? []
                : sortBenchmarks(
                    [...(isCountryCode(locationId) ? [currentLocationBenchmark] : []), ...benchmarks],
                    'currentFyYtd',
                    locationId
                  ).slice(0, 4)
            }
            label=""
            headers={isGoalsDefinedForBenchmarks ? benchmarkingHeadersWithGoals : benchmarkingHeaders}
            locationId={locationId}
            openModal={() => setBenchmarkModalOpen(true)}
            renderBenchmark={getRenderBenchmark(isGoalsDefinedForBenchmarks, isFunctionAreaCentres)}
            totalLocations={benchmarks.length}
          />
          <KPIPerformance
            key="goals"
            heading="KPI Performance"
            units={['people YTD']}
            kpis={
              !peopleImpacted
                ? []
                : ([
                    {
                      key: 'People Support In Total',
                      value: formatAbsoluteNumber(sumBy(currFyData, 'totalSupported'))
                    },
                    {
                      key: 'People Supported by Substantive and Enduring Social Initiatives',
                      value: formatAbsoluteNumber(sumBy(currFyData, 'strongContribution')),
                      keyClassNames: ['Bold'],
                      valueClassNames: ['Bold']
                    },
                    ...(doNotShowGoal
                      ? []
                      : [
                          {
                            key: goalHeadingYTD,
                            value: goal === undefined || isFunctionAreaCentres ? 'N/A' : formatAbsoluteNumber(ytdGoal),
                            keyClassNames: ['Bold'],
                            valueClassNames: ['Bold']
                          }
                        ])
                  ].filter(Boolean) as KPI[])
            }
          />
        </CardRow>
        <MainCard
          title="Number of people supported by substantive and enduring social initiatives"
          subtitle={
            <>
              <div className={styles.MainCardSummary}>
                {goal && (
                  <span>
                    {!isNil(ytdGoal) ? formatAbsoluteNumber(ytdGoal) : 'N/A'}
                    <span className="Label">{goalHeadingYTD}</span>
                  </span>
                )}
                <span>
                  {formatAbsoluteNumber(sumBy(currFyData, 'strongContribution'))}
                  <span className="Label">people YTD</span>
                </span>
              </div>
            </>
          }
        >
          <div className="GraphContainer">
            <FinancialYearChartContainer
              testId="social-impact-main-chart"
              series={series}
              domainFromSerie={prevFyName}
              generator={lineChart}
              lineChartConfiguration={{ focusStyle: 'none', startFromZero: true }}
              yAxisTitle="no. of people"
              tooltipSummary={tooltipSummary}
              filterFY24Data
            />
          </div>
        </MainCard>
        <SocialImpactGraphSideBarCards
          peopleImpacted={peopleImpacted}
          currFyName={currFyName}
          actualPeriodDate={actualPeriodDate}
        />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarks}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={countryBenchmark}
          headers={isGoalsDefinedForBenchmarks ? benchmarkingHeadersWithGoals : benchmarkingHeaders}
          isOpen={benchmarkModalOpen}
          locationId={locationId}
          renderBenchmark={getRenderBenchmark(isGoalsDefinedForBenchmarks, isFunctionAreaCentres)}
          sortBy="currentFyYtd"
          sortDirection="desc"
          title="impacted people"
        />
      )}
    </div>
  )
}
