import { flatMap, pickBy } from 'lodash'
import * as qs from 'qs'
import { useEffect } from 'react'

import {
  ApiCountry,
  ApiPlanetResponseBase,
  ApiSite,
  CountryRanking,
  HFBShare,
  Watchlist,
  WeeklyHfbArticleSalesData,
  WeeklySalesData,
  WhatIfPlanetFraction,
  DisabledFeatures,
  MonthlySentimentsWithFY,
  MonthlySentiments,
  Topic,
  UserInformation,
  WithLastUpdated,
  MonthlyTopics,
  NotificationsSeen,
  RenewableEnergyShare,
  FlowOption,
  InstoreLinkResponse,
  DataAvailability,
  WasteRecycling,
  RecoveredProducts,
  PeopleImpacted,
  SiteFunction,
  FoodFootprint,
  AsIsSales,
  BenchmarkResponse,
  PlanetBenchmarkResult,
  ProfitBenchmarkResult,
  WithDateRange,
  CountryCode,
  ApiCluster,
  ClimateBenchmark,
  SalesYtd,
  PppSalesBenchmark,
  ApiTotalFootprint,
  PlanetFootprint,
  Insight,
  SiteRanking,
  CustomerDeliveries,
  ChangeMakers,
  DagConfig,
  DagRuns,
  DagRunInfo,
  YtdFootprintAndGoals,
  YtdGoals,
  FairAndEqualBenchmark,
  GoalsResponse,
  FoodIngredientsBenchmark,
  ClimateIntensityBenchmark,
  WithRatio,
  ClimateFootprintAction,
  EnergyEfficiencyRolling,
  EnergyEfficiencyBenchmark,
  CustomerTravelBenchmark,
  CustomerTravelFootprint,
  RefrigerantFactorsType,
  WaterEfficiencyBenchmark,
  WaterEfficiency,
  ClimateResultsBenchmark,
  WaterSmallCardData,
  WeeklySalesAndQty,
  RevenueBase,
  RevenueBenchmark
} from '../../api/src/common-types'
import { isLocal, msalApp } from '../Authentication'
import { LocationOptionClass } from '../components/LocationSearch'
import { AvailableDates } from '../../api/src/types/dates'
import { KpiFootprintModalContentProps } from '../components/ModalContent/KpiFootprintModalContent'
import { WaterEfficiencySlide } from '../components/InStore/WaterEfficiencySlide'

const baseUrl = '/api/v1'

export enum GeographicalScope {
  Global = 'global',
  Country = 'country',
  Site = 'site'
}

interface AppVersion {
  appVersion: string
}

export enum FootprintType {
  Emissions = 'emissions',
  Waste = 'wastes',
  Water = 'waters'
}

export interface SelectorWithCountryCodes {
  locationId: string
  site: string
  countryCodes: CountryCode[]
  func?: SiteFunction[]
  start_fy?: number
  end_fy?: number
  isOld?: boolean
  channel?: string
}

interface Selector {
  site?: string
  country?: string
  start_fy?: number
  end_fy?: number
  locationId?: string
}

export interface SelectorWithFunctions extends Selector {
  func: SiteFunction[]
}

async function getAccessToken() {
  // In kiosk mode there are no accounts
  if (isLocal() || msalApp.getAllAccounts().length === 0) {
    return undefined
  }
  const scopes = ['fbcb1d9f-1c7c-4294-a4ae-2cb3133a0130/.default']
  try {
    const authResult = await msalApp.acquireTokenSilent({ scopes })
    if (authResult && authResult.accessToken) {
      return authResult.accessToken
    } else {
      throw new Error('No access token received')
    }
  } catch (e) {
    localStorage.clear() // Sometimes MSAL cache gets corrupted, clearing localStorage works around it
    await msalApp.acquireTokenRedirect({ scopes }) // this will redirect from the page, so return value does not matter
    return ''
  }
}

export const msGraphScopes = ['User.Read', 'Mail.Send']

async function getGraphToken(): Promise<string> {
  if (isLocal()) {
    return 'dummyLocalToken'
  }
  try {
    const authResult = await msalApp.acquireTokenSilent({ scopes: msGraphScopes })
    if (authResult && authResult.accessToken) {
      return authResult.accessToken
    } else {
      throw new Error('No access token received')
    }
  } catch (e) {
    localStorage.clear() // Sometimes MSAL cache gets corrupted, clearing localStorage works around it
    msalApp.acquireTokenRedirect({ scopes: msGraphScopes }) // this will redirect from the page, so return value does not matter
    return ''
  }
}

async function fetchOrRedirect(
  url: string,
  additionalHeaders: Record<string, string> = {},
  signal?: AbortSignal
): Promise<Response> {
  const accessToken = await getAccessToken()
  const authHeaders = pickBy(
    {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      InStoreAuth: accessToken ? undefined : sessionStorage['InStoreAuth'],
      InStoreKey: accessToken ? undefined : sessionStorage['InStoreKey']
    },
    v => v != null
  )
  const response = await fetch(url, {
    credentials: 'same-origin',
    headers: { ...additionalHeaders, ...authHeaders },
    signal
  })
  return response
}

type Abortable = (signal: AbortSignal) => Promise<void> | void

export function useEffectWithAbort(abortableFunction: Abortable, dependencies: React.DependencyList) {
  useEffect(() => {
    const abortController = new AbortController()
    const { signal } = abortController

    const fetchData = async () => {
      await abortableFunction(signal)
    }
    fetchData()

    return () => {
      abortController.abort()
    }
  }, dependencies)
}

export async function getWeeklySales(
  location: string,
  startFY: number,
  endFY: number,
  channel: string,
  signal?: AbortSignal
): Promise<WeeklySalesData[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/growths/sales/weekly?${qs.stringify({
      location: location,
      start_fy: startFY,
      end_fy: endFY,
      channel
    })}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getYtdSales(
  selector: SelectorWithCountryCodes,
  signal?: AbortSignal
): Promise<WithLastUpdated<SalesYtd>> {
  const response = await fetchOrRedirect(`${baseUrl}/growths/sales/ytd?${qs.stringify(selector)}`, undefined, signal)
  return response.json()
}

export async function getRolling12MonthsWeeklySales(
  selector: SelectorWithCountryCodes,
  signal?: AbortSignal
): Promise<WeeklySalesAndQty[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/growths/sales/rolling12months?${qs.stringify(selector)}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getMonthlyFootprint(
  selector: SelectorWithCountryCodes,
  signal?: AbortSignal
): Promise<WithDateRange<YtdFootprintAndGoals[]>> {
  const response = await fetchOrRedirect(`${baseUrl}/footprints/monthly?${qs.stringify(selector)}`, undefined, signal)
  return response.json()
}

export async function getEnergyEfficiencyRolling(
  locationId: string,
  func: SiteFunction[],
  curr_fy: number
): Promise<WithDateRange<EnergyEfficiencyRolling[]>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/footprints/energy-efficiency/rolling?${qs.stringify({ locationId, func, curr_fy })}`
  )
  return response.json()
}

export async function getWaterEfficiency(
  locationId: string,
  func: SiteFunction[],
  curr_fy: number
): Promise<WithDateRange<WaterEfficiency[]>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/footprints/water-efficiency?${qs.stringify({ locationId, func, curr_fy })}`
  )
  return response.json()
}

export async function getCustomerTravelFootprint(
  locationId: string,
  func: SiteFunction[]
): Promise<WithDateRange<CustomerTravelFootprint[]>> {
  const response = await fetchOrRedirect(`${baseUrl}/footprints/customer-travel?${qs.stringify({ locationId, func })}`)
  return response.json()
}

export async function getCustomerTravelBenchmarking(
  countryCode: CountryCode,
  func: SiteFunction[]
): Promise<{ data: CustomerTravelBenchmark[]; currFY: number }> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/customer-travel?${qs.stringify({ countryCode, func })}`
  )
  return response.json()
}

export async function getInsights(selector: SelectorWithFunctions): Promise<Insight[]> {
  const response = await fetchOrRedirect(`${baseUrl}/dashboard/insights?${qs.stringify(selector)}`)
  return response.json()
}

export async function getSnapshotInsights(location: string, func: SiteFunction[]): Promise<Insight[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/dashboard/insights-for-location?${qs.stringify({ location, func })}`
  )
  return response.json()
}

interface WithValueTypeAndLabel {
  value: string
  type: GeographicalScope
  label: string
  className: LocationOptionClass
}

export type CountryWithType = ApiCountry & WithValueTypeAndLabel
export type SiteWithType = ApiSite & WithValueTypeAndLabel
export type LocationWithType = CountryWithType | SiteWithType

export function isSite(location: LocationWithType): location is SiteWithType {
  return location.type === GeographicalScope.Site
}

export function isCountry(location: LocationWithType): location is CountryWithType {
  return location.type === GeographicalScope.Country
}

export function isCountryOrGlobal(location: LocationWithType): location is CountryWithType {
  return location.type === GeographicalScope.Global || location.type === GeographicalScope.Country
}

export async function getAllLocations(): Promise<LocationWithType[]> {
  const [countries, sites] = await Promise.all([
    fetchOrRedirect(`${baseUrl}/locations/countries`),
    fetchOrRedirect(`${baseUrl}/locations/sites`)
  ]).then(([countries, sites]) => {
    return Promise.all([countries.json(), sites.json()])
  })

  const sortedEntries = countries
    .map((country: ApiCountry) => ({
      ...country,
      label: country.countryName,
      value: country.countryCode,
      className: 'CountryOption',
      type: country.countryCode === 'ALL' ? GeographicalScope.Global : GeographicalScope.Country
    }))
    .sort((lhs: CountryWithType, rhs: CountryWithType) =>
      rhs.countryCode === 'ALL' ? 1 : lhs.countryName.localeCompare(rhs.countryName)
    )
  return flatMap(sortedEntries, country => {
    const sitesInCountry = sites
      .filter((location: ApiSite) => location.countryCode === country.countryCode)
      .map((site: ApiSite) => ({
        ...site,
        label: `${site.siteName}`,
        value: site.siteId,
        className: 'SiteOption',
        type: GeographicalScope.Site
      }))
    return [country, ...sitesInCountry]
  })
}

export function getClusters(): Promise<ApiCluster[]> {
  return fetchOrRedirect(`${baseUrl}/locations/clusters`).then(response => response.json())
}

export async function getCountryRankings(func: SiteFunction[]): Promise<CountryRanking[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/locations/countries/ranking?${qs.stringify({
      func: func
    })}`
  )
  return response.json()
}

export async function getSiteRankings(func: SiteFunction[]): Promise<SiteRanking[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/locations/sites/ranking?${qs.stringify({
      func: func
    })}`
  )
  return response.json()
}

export async function getEnergyTypes(): Promise<string[]> {
  const response = await fetchOrRedirect(`${baseUrl}/footprints/emissions/types`)
  return response.json()
}

export async function getRefrigerantsFactors(signal: AbortSignal): Promise<RefrigerantFactorsType> {
  const response = await fetchOrRedirect(`${baseUrl}/footprints/emissions/factors`, {}, signal)
  return response.json()
}

export async function getPeopleImpacted(
  selector: SelectorWithCountryCodes
): Promise<{ lastUpdated: string; data: PeopleImpacted[]; actualPeriod: string }> {
  const response = await fetchOrRedirect(`${baseUrl}/people/impacted?${qs.stringify(selector)}`)
  return response.json()
}

export async function getSocialImpactGoal(siteOrCountry: string): Promise<number | null> {
  return fetchOrRedirect(`${baseUrl}/people/social-impact-goal?${qs.stringify({ siteOrCountry })}`)
    .then(r => r.json())
    .then(r => r.value)
}

export async function getPeopleImpactedBenchmarks(
  countryCodes: CountryCode[],
  func: SiteFunction[],
  currFy: number,
  prevFy: number
): Promise<WithRatio<FairAndEqualBenchmark[]>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/people/benchmarks?${qs.stringify({ countryCodes, func, currFy, prevFy })}`
  )
  return response.json()
}

export async function getChangeMakers(
  selector: SelectorWithCountryCodes & { prevFy?: boolean }
): Promise<{ lastUpdated: string; data: ChangeMakers[]; actualPeriod: string }> {
  const response = await fetchOrRedirect(`${baseUrl}/people/change-makers?${qs.stringify(selector)}`)
  return response.json()
}

export async function getHFBPPPShares(location: string, signal?: AbortSignal): Promise<HFBShare[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/growths/sales/hfb?${qs.stringify({ location: location })}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getHfbTopArticleSales(
  hfbName: string,
  location: string,
  flow: FlowOption,
  isSales: boolean,
  signal?: AbortSignal
): Promise<WeeklyHfbArticleSalesData[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/growths/sales/hfb/articles?${qs.stringify({ hfb_name: hfbName, location: location, flow, isSales })}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getAllHfbTopArticleSales(
  location: string,
  channel: FlowOption,
  isSales: boolean,
  signal?: AbortSignal
): Promise<WeeklyHfbArticleSalesData[]> {
  return getHfbTopArticleSales('%', location, channel, isSales, signal)
}

export async function getAppVersion(): Promise<AppVersion> {
  const response = await fetchOrRedirect('/version')
  return response.json()
}

export async function getSustainablePerception(
  startDate: string,
  endDate: string,
  country: string
): Promise<MonthlySentiments[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/mentions/times?${qs.stringify({ start_date: startDate, end_date: endDate, country })}`
  )
  return response.json()
}

export async function getMonthlyPerception(
  country: string
): Promise<{ currentFY: MonthlySentimentsWithFY[]; previousFY: MonthlySentimentsWithFY[] }> {
  const response = await fetchOrRedirect(`${baseUrl}/mentions/monthly?${qs.stringify({ country })}`)
  return response.json()
}

export async function getMonthlyTopicsByCategory(
  startDate: string,
  endDate: string,
  country: string
): Promise<MonthlyTopics[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/mentions/topicsbycategory?${qs.stringify({ start_date: startDate, end_date: endDate, country })}`
  )
  return response.json()
}

export async function getTopics(country: string): Promise<Topic[]> {
  const response = await fetchOrRedirect(`${baseUrl}/mentions/topics?${qs.stringify({ country })}`)
  return response.json()
}

export async function getPlanetFootprints(
  type: FootprintType,
  selector: SelectorWithCountryCodes
): Promise<WithDateRange<ApiPlanetResponseBase[]>> {
  const response = await fetchOrRedirect(`${baseUrl}/footprints/${type}?${qs.stringify(selector)}`)
  return response.json()
}

export async function getWasteRecycling(selector: SelectorWithCountryCodes): Promise<WasteRecycling[]> {
  const response = await fetchOrRedirect(`${baseUrl}/footprints/wastes/recycling?${qs.stringify(selector)}`)
  return response.json()
}

export async function getRenewableEnergyShare(
  selector: SelectorWithCountryCodes
): Promise<WithDateRange<RenewableEnergyShare[]>> {
  const response = await fetchOrRedirect(`${baseUrl}/footprints/renewable?${qs.stringify(selector)}`)
  return response.json()
}

export async function getTotalFootprint(
  selector: SelectorWithCountryCodes,
  footprintType?: PlanetFootprint[],
  signal?: AbortSignal
): Promise<WithDateRange<ApiTotalFootprint[]>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/footprints/total?${qs.stringify({ ...selector, footprintType })}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getWaterFootprint(
  selector: SelectorWithCountryCodes,
  footprintType?: PlanetFootprint,
  signal?: AbortSignal
): Promise<WithDateRange<ApiTotalFootprint[]>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/footprints/water?${qs.stringify({ ...selector, footprintType })}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getTotalFootprintSBTi(
  selector: SelectorWithCountryCodes,
  footprintType?: PlanetFootprint,
  signal?: AbortSignal
): Promise<WithDateRange<ApiTotalFootprint[]>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/footprints/total-sbti?${qs.stringify({ ...selector, footprintType })}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getFoodFootprint(location: string, fy?: number): Promise<WithLastUpdated<FoodFootprint[]>> {
  const response = await fetchOrRedirect(`${baseUrl}/footprints/food?${qs.stringify({ location, fy })}`)
  return response.json()
}

export async function getClimateIntensityBenchmarks(
  locationId: string,
  fy?: number,
  month?: number
): Promise<ClimateIntensityBenchmark> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/climate-intensity?${qs.stringify({
      locationId,
      fy,
      month
    })}`
  )
  return response.json()
}

export async function getFoodIngredientsBenchmarks(locationId: string): Promise<FoodIngredientsBenchmark[]> {
  const response = await fetchOrRedirect(`${baseUrl}/benchmarking/food?${qs.stringify({ locationId })}`)
  return response.json()
}

export async function getRecoveredProducts(selector: SelectorWithCountryCodes): Promise<RecoveredProducts[]> {
  const response = await fetchOrRedirect(`${baseUrl}/products/recovered?${qs.stringify(selector)}`)
  return response.json()
}

export async function getAsIsSales(
  location: string,
  startFy: number,
  endFy: number
): Promise<WithDateRange<AsIsSales[]>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/growths/sales/asis?${qs.stringify({ location, start_fy: startFy, end_fy: endFy })}`
  )
  return response.json()
}

export async function getPlanetBenchmarking(
  countryCodes: CountryCode[],
  func: SiteFunction[],
  locationId: string
): Promise<BenchmarkResponse<PlanetBenchmarkResult>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/planet?${qs.stringify({ countryCodes, func, locationId })}`
  )
  return response.json()
}

export async function getProfitBenchmarking(
  countryCode: string,
  func: SiteFunction[],
  locationId: string
): Promise<BenchmarkResponse<ProfitBenchmarkResult>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/profit?${qs.stringify({ countryCode, func, locationId })}`
  )
  return response.json()
}

export async function getClimateBenchmarking(
  countryCode: CountryCode,
  func: SiteFunction[],
  signal?: AbortSignal
): Promise<ClimateBenchmark[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/climate?${qs.stringify({ countryCode, func })}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getClimateSBTi(
  countryCode: CountryCode,
  func: SiteFunction[],
  signal?: AbortSignal
): Promise<ClimateBenchmark[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/climate-sbti?${qs.stringify({ countryCode, func })}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getEnergyEfficiencyBenchmarking(
  countryCode: CountryCode,
  func: SiteFunction[],
  curr_fy: number
): Promise<EnergyEfficiencyBenchmark[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/energy-efficiency?${qs.stringify({ countryCode, func, curr_fy })}`
  )
  return response.json()
}

export async function getWaterEfficiencyBenchmarking(
  countryCode: CountryCode,
  func: SiteFunction[]
): Promise<WaterEfficiencyBenchmark[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/water-efficiency?${qs.stringify({ countryCode, func })}`
  )
  return response.json()
}

export async function getWaterEfficiencySmallGraphData(
  locationId: string,
  func: SiteFunction[]
): Promise<WaterSmallCardData[]> {
  const response = await fetchOrRedirect(`${baseUrl}/water/efficiency?${qs.stringify({ locationId, func })}`)
  return response.json()
}

export async function getCustomerDeliveriesBenchmarking(countryCode: CountryCode): Promise<ClimateResultsBenchmark[]> {
  const response = await fetchOrRedirect(`${baseUrl}/benchmarking/customer-deliveries?${qs.stringify({ countryCode })}`)
  return response.json()
}

export async function getPppSalesBenchmarking(countryCode: string, signal?: AbortSignal): Promise<PppSalesBenchmark[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/ppp-sales?${qs.stringify({ countryCode })}`,
    undefined,
    signal
  )
  return response.json()
}

export async function getChangeMakersBenchmarking(
  countryCodes: CountryCode[],
  func: SiteFunction[],
  currFy: number,
  prevFy: number
): Promise<WithRatio<FairAndEqualBenchmark[]>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/benchmarking/change-makers?${qs.stringify({ countryCodes, func, currFy, prevFy })}`
  )
  return response.json()
}

export async function getLeaderboardWatchlist(): Promise<Watchlist> {
  const graphToken = await getGraphToken()
  const response = await fetchOrRedirect(`${baseUrl}/user/watchlist`, { GraphToken: graphToken })
  return response.json()
}

export async function saveLeaderboardWatchlist(watchList: string[]) {
  const [accessToken, graphToken] = await Promise.all([getAccessToken(), getGraphToken()])
  await fetch(`${baseUrl}/user/watchlist`, {
    credentials: 'same-origin',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      GraphToken: graphToken
    },
    body: JSON.stringify({ locations: watchList })
  })
}

export async function getWhatIfPlanetFractions(selector: SelectorWithCountryCodes): Promise<WhatIfPlanetFraction[]> {
  const response = await fetchOrRedirect(`${baseUrl}/whatif/planet/fractions?${qs.stringify(selector)}`)
  return response.json()
}

export async function getWhatIfPlanetGoals(selector: SelectorWithCountryCodes): Promise<YtdGoals[]> {
  const response = await fetchOrRedirect(`${baseUrl}/whatif/planet/goals?${qs.stringify(selector)}`)
  return response.json()
}

export async function getDisabledFeatures(): Promise<DisabledFeatures[]> {
  const response = await fetchOrRedirect(`${baseUrl}/dashboard/features`)
  return response.json()
}

export async function getUserInformation(): Promise<UserInformation> {
  const graphToken = await getGraphToken()
  const response = await fetchOrRedirect(`${baseUrl}/user/me`, { GraphToken: graphToken })
  return response.json()
}

export async function getNotificationsLastSeen(): Promise<NotificationsSeen> {
  const graphToken = await getGraphToken()
  const response = await fetchOrRedirect(`${baseUrl}/user/notifications`, { GraphToken: graphToken })
  return response.json()
}

export async function saveNotificationsLastSeen() {
  const [accessToken, graphToken] = await Promise.all([getAccessToken(), getGraphToken()])
  await fetch(`${baseUrl}/user/notifications`, {
    credentials: 'same-origin',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      GraphToken: graphToken
    }
  })
}

export async function subscribeNewsletter() {
  const [accessToken, graphToken] = await Promise.all([getAccessToken(), getGraphToken()])
  await fetch(`${baseUrl}/user/newsletter/subscribe`, {
    credentials: 'same-origin',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      GraphToken: graphToken
    }
  })
}

export async function unsubscribeNewsletter() {
  const [accessToken, graphToken] = await Promise.all([getAccessToken(), getGraphToken()])
  await fetch(`${baseUrl}/user/newsletter/unsubscribe`, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      GraphToken: graphToken
    }
  })
}

export async function sendFeedback(
  userId: string,
  name: string,
  email: string,
  subject: string,
  message: string,
  signup: boolean,
  url: string,
  userAgent: string
): Promise<boolean> {
  const [accessToken, graphToken] = await Promise.all([getAccessToken(), getGraphToken()])
  const response = await fetch(`${baseUrl}/user/mail`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      GraphToken: graphToken
    },
    body: JSON.stringify({ userId, name, email, subject, message, signup, url, userAgent })
  })
  return response.ok
}

export async function createInstoreLink(location: string, slides: string[]): Promise<InstoreLinkResponse> {
  const accessToken = await getAccessToken()
  const response = await fetch(`${baseUrl}/user/instorelink`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify({ location: location, screens: slides })
  })
  return response.json()
}

export async function getCustomerDeliveriesData(
  countryCode: string
): Promise<{ data: CustomerDeliveries[]; lastModified: string }> {
  return fetchOrRedirect(`${baseUrl}/deliveries/by-country?${qs.stringify({ countryCode })}`).then(r => r.json())
}

export async function getDataAvailability(): Promise<DataAvailability> {
  const response = await fetchOrRedirect(`${baseUrl}/dashboard/data-availability`)
  return response.json()
}

export async function triggerJob<D extends DagConfig>(endpoint: string, payload: D): Promise<DagRunInfo> {
  const accessToken = await getAccessToken()
  const response = await fetch(`${baseUrl}/self-service/job/${endpoint}`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(payload)
  })

  return response.json()
}

export async function getJobInfo(endpoint: string): Promise<DagRuns | null> {
  const response = await fetchOrRedirect(`${baseUrl}/self-service/job/${endpoint}`)
  return response?.json() ?? null
}

export async function getGoals(countryCode: CountryCode, func: SiteFunction[], fy: number): Promise<GoalsResponse> {
  return fetchOrRedirect(
    `${baseUrl}/goals/all?${qs.stringify({
      countryCode,
      func,
      fy,
      // Only for In Store page because auth middleware requires this query param
      location: countryCode
    })}`
  ).then(r => r.json())
}

export async function getSelectedGoals(
  siteOrCountry: string,
  func: SiteFunction[],
  goalType: string,
  signal: AbortSignal,
  fromFy?: number
): Promise<Record<string, number>> {
  return fetchOrRedirect(
    `${baseUrl}/goals/specific?${qs.stringify({ siteOrCountry, func, goalType, fromFy })}`,
    undefined,
    signal
  ).then(r => r.json())
}

export async function getAvailableDatesFood(siteOrCountry?: string): Promise<AvailableDates> {
  const response = await fetchOrRedirect(`${baseUrl}/dates/food?${qs.stringify({ siteOrCountry })}`)
  return response.json()
}

export async function getClimateActions(
  siteOrCountry: string,
  func: SiteFunction[],
  signal: AbortSignal
): Promise<Record<string, ClimateFootprintAction>> {
  return fetchOrRedirect(
    `${baseUrl}/footprints/actions?${qs.stringify({ siteOrCountry, func })}`,
    undefined,
    signal
  ).then(r => r.json())
}

export async function getClimateActionsExtended(
  siteOrCountry: string,
  func: SiteFunction[],
  signal: AbortSignal
): Promise<Record<string, KpiFootprintModalContentProps[]>> {
  return fetchOrRedirect(
    `${baseUrl}/footprints/extended-actions?${qs.stringify({ siteOrCountry, func })}`,
    undefined,
    signal
  ).then(r => r.json())
}

export async function getWaterEfficiencyFootprint(
  countryCodes: CountryCode,
  func: SiteFunction[],
  currFY: number
): Promise<WithDateRange<WaterEfficiencySlide[]>> {
  const response = await fetchOrRedirect(
    `${baseUrl}/footprints/slide-efficiency?${qs.stringify({ countryCodes, func, currFY })}`
  )
  return response.json()
}

export async function getCircularRevenue(
  selector: SelectorWithCountryCodes,
  signal?: AbortSignal
): Promise<RevenueBase[]> {
  const response = await fetchOrRedirect(`${baseUrl}/revenue/circular?${qs.stringify(selector)}`, undefined, signal)
  return response.json()
}

export async function getPerformanceRevenue(
  selector: SelectorWithCountryCodes,
  signal?: AbortSignal
): Promise<WithLastUpdated<RevenueBase>> {
  const response = await fetchOrRedirect(`${baseUrl}/revenue/performance?${qs.stringify(selector)}`, undefined, signal)
  return response.json()
}

export async function getBenchmarksRevenue(countryCode: string, signal?: AbortSignal): Promise<RevenueBenchmark[]> {
  const response = await fetchOrRedirect(
    `${baseUrl}/revenue/benchmarks?${qs.stringify({ countryCode })}`,
    undefined,
    signal
  )
  return response.json()
}
